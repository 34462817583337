import { Form, FastField } from 'formik';
import { validate } from 'email-validator';

import { InputField, Layout, Cell } from '..';

export interface AuthFormValues {
  email: string;
  password: string;
}

export interface AuthFormErrors {
  email?: string;
  password?: string;
}

export const validateAuthForm = (values: AuthFormValues) => {
  const errors: AuthFormErrors = {};

  if (!values.email.trim().length) {
    errors.email = `Can't be empy!`;
  }

  if (!validate(values.email)) {
    errors.email = `Does't look like a valid email :P`;
  }

  if (values.password.length < 3) {
    errors.password = 'This is a very short password. Try 3 chars or more!';
  }

  return errors;
};

export const AuthForm: React.FC = () => {
  return (
    <Form className="w-full">
      <Layout>
        <Cell>
          <FastField component={InputField} label="Email" placeholder="you@email.com" name="email" autoFocus />
        </Cell>
        <Cell>
          <FastField type="password" component={InputField} label="Password" placeholder="••••••••" name="password" />
        </Cell>
      </Layout>
    </Form>
  );
};
