import { Popover, Transition } from '@headlessui/react';
import { SketchPicker } from 'react-color';
import { useSettings } from '../../hooks';
import { ColorPalette } from '../color-palette';

interface Props {
  type?: 'palette' | 'generic';
  value: string;
  onChange: (value: string) => void;
}

export const ColorPicker: React.FC<Props> = ({ type = 'generic', value, onChange }) => {
  const { settings } = useSettings();
  const { colors } = settings;

  const backgroundColor = type === 'generic' ? value : colors[value];

  return (
    <Popover className="relative">
      <Popover.Button>
        <div className="w-48 h-48 cursor-pointer" style={{ backgroundColor }} />
      </Popover.Button>
      <Transition
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 -translate-y-1 z-50"
        enterTo="opacity-100 translate-y-0 z-50"
        leave="transition ease-in duration-150 z-50"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-1"
      >
        <Popover.Panel className="absolute shadow-lg bg-white z-50 h-128">
          {type === 'generic' ? (
            <SketchPicker disableAlpha color={value} onChange={(c) => onChange(c.hex)} presetColors={[]} />
          ) : (
            <ColorPalette value={value} onSelect={onChange} />
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
