import { GripVertical, Settings2, Trash } from 'lucide-react';
import { Popover, Transition } from '@headlessui/react';
import { NodeViewProps } from '@tiptap/react';

interface Props extends NodeViewProps {
  onClickSettings?: () => void;
}

export const Focus: React.FC<Props> = ({ children, onClickSettings, deleteNode }) => {
  return (
    <Popover className="relative group flex flex-1">
      <Popover.Button contentEditable={false} className="absolute top-0 left-[-24px] ">
        <div
          className="drag-handle opacity-0 group-hover:opacity-100 text-gray-400 p-4 hover:bg-gray-100 cursor-pointer rounded-sm"
          draggable
          data-drag-handle={true}
        >
          <GripVertical width={16} height={16} />
        </div>
      </Popover.Button>
      {onClickSettings && (
        <div
          className="opacity-0 group-hover:opacity-100 absolute top-24 left-[-24px] text-gray-400 p-4 hover:bg-gray-100 cursor-pointer rounded-sm"
          contentEditable={false}
          onClick={onClickSettings}
        >
          <Settings2 width={16} height={16} />
        </div>
      )}
      <Transition
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 -translate-y-1 z-10"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-50"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-1"
      >
        <Popover.Panel className="absolute left-0 -translate-x-[100%] top-16 z-10 py-8 px-4 rounded-md transform shadow-lg bg-white">
          <span
            onClick={deleteNode}
            className="text-danger hover:bg-gray-100 hover:bg-opacity-75 flex items-center p-12 text-sm font-medium rounded-md cursor-pointer"
          >
            <Trash size={16} />
            <span className="flex-1 pl-8">Delete</span>
          </span>
        </Popover.Panel>
      </Transition>
      <div className="flex flex-1">{children}</div>
    </Popover>
  );
};
