import { useQuery } from 'react-query';
import { authService } from '../portal-api-client';

import { User } from '../types';

export const useUser = () => {
  const query = useQuery<User>('user', async () => {
    const accessToken = localStorage.getItem('token');
    if (accessToken) {
      const { user } = await authService.authenticate({ accessToken, strategy: 'jwt' });
      return user;
    } else {
      throw Error('Bad bad bad, auth bad, this code needs a remake');
    }
  });

  return query;
};
