import { useEditor, EditorContent as TipTapContent, JSONContent } from '@tiptap/react';
import { useEffect } from 'react';
import { debounce } from 'lodash';

import { EditorBubbleMenu, EditorSideNav, EditorFloatingMenu } from '..';
import { useMutations } from '../../hooks';
import { extensions } from '../../tiptap';

interface Props {
  projectId: string;
  pageId: string;
  value: JSONContent;
}

const updateDebounce = debounce((f) => f(), 1500);

export const EditorContent: React.FC<Props> = ({ value, pageId }) => {
  const { savePage } = useMutations();

  const editor = useEditor({
    onUpdate: ({ editor }) => {
      updateDebounce(() => {
        savePage.mutate({ id: pageId, value: editor.getJSON() });
      });
    },
    extensions,
    content: value,
  });

  useEffect(() => {
    editor?.chain().blur().setContent(value).run();
  }, [value]);

  return (
    <div>
      <div className="flex flex-row">
        <EditorSideNav />
        <div className="flex-1">
          <EditorBubbleMenu editor={editor} />
          <EditorFloatingMenu />
          <TipTapContent className="flex-1 pt-64 pb-64" editor={editor} />
        </div>
      </div>
    </div>
  );
};
