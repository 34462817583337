import { Editor } from '@tiptap/core';
import { Trash } from 'lucide-react';
import { Button, IconPicker, Input } from '..';
import { FormField } from '../form-field';

interface Props {
  editor: Editor;
  cards: any[];
  updateCards: (node: any) => void;
}

export const CardEditor: React.FC<Props> = ({ cards, updateCards, editor }) => {
  const onPickIcon = () => {};

  return (
    <div>
      {cards.map((card, index) => (
        <div className="flex gap-8 pt-4 pb-8">
          {/* <IconPicker selected="activity" onSelect={onPickIcon} /> */}
          <FormField label="Card Title">
            <Input
              value={card.attrs.title}
              onChange={(e) => {
                const newCard = editor.schema.nodes.card.create({ title: e.target.value });
                const updatedCards = cards.map((card, i) => (i === index ? newCard : card));
                updateCards(updatedCards);
              }}
            />
          </FormField>
          <FormField label="Link">
            <Input
              // value={card.attrs.title}
              value=""
              onChange={(e) => {
                // const newCard = editor.schema.nodes.card.create({ title: e.target.value });
                // const updatedCards = cards.map((card, i) => (i === index ? newCard : card));
                // updateCards(updatedCards);
              }}
            />
          </FormField>
          {/* <div className="hover:bg-gray-200 cursor-pointer p-4 rounded-sm">
            <Trash
              size={16}
              onClick={() => {
                const updatedCards = cards.filter((card, i) => i !== index);
                updateCards(updatedCards);
              }}
            />
          </div> */}
        </div>
      ))}
      <Button
        onClick={() => {
          const newCard = editor.schema.nodes.card.create({ title: 'New Card' });
          updateCards([...cards, newCard]);
        }}
      >
        + Add Card
      </Button>
    </div>
  );
};
