import { useParams } from 'react-router-dom';

import { useProject } from '../../hooks';
import { Editor } from '..';

export const EditorPage: React.FC = () => {
  const { '*': pageSlug } = useParams<{ projectSlug: string; '*': string }>();
  const { data: project } = useProject();

  if (!project) {
    return <div>'no project.';</div>;
  }

  const page = project.pages.find((page) => page.slug === pageSlug);

  if (!page) {
    return <div>'no page.';</div>;
  }

  return <Editor project={project} page={page} />;
};
