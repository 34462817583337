import { mergeAttributes, ReactNodeViewRenderer } from '@tiptap/react';
import { Node } from '@tiptap/core';

import { Component } from './box-component';
import { attribute } from './attribute';

export interface BoxOptions {
  HTMLAttributes: Record<string, any>;
}

// tailwind only bundles classes that it finds somewhere in the file.
// in order to have dynamic classes, they need to be defined someplace.
// this sucks, but I'm not sure how to best deal with this atm.
// hence this array.

const spans = [
  'col-span-0',
  'col-span-1',
  'col-span-2',
  'col-span-3',
  'col-span-4',
  'col-span-5',
  'col-span-6',
  'col-span-7',
  'col-span-8',
  'col-span-9',
  'col-span-10',
  'col-span-11',
  'col-span-12',
  'py-8',
  'my-8',
  'bg-pink',
  'bg-blue-100',
  'bg-blue-50',
  'rounded-none',
  'rounded-md',
  'drop-shadow-xl',
  'gap-4',
  'gap-0',
  'gap-8',
  'gap-12',
  'text-left',
  'text-right',
  'text-center',
  'text-justify',
];

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    box: {
      insertBox: (attributes: any) => ReturnType;
    };
  }
}

export const Box = Node.create<BoxOptions>({
  name: 'box',
  marks: '',
  content: 'block+',
  group: 'block',
  isolating: true,
  defining: true,
  draggable: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      span: attribute('span', '12'),
      py: attribute('py', '0'),
      my: attribute('my', '0'),
      bg: attribute('bg', 'transparent'),
      rounded: attribute('rounded', 'none'),
      'drop-shadow': attribute('drop-shadow', 'none'),
    };
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'box',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: `
          block
          col-span-${node.attrs.span}
          py-${node.attrs.py}
          my-${node.attrs.my}
          bg-${node.attrs.bg}
          rounded-${node.attrs.rounded}
          drop-shadow-${node.attrs['drop-shadow']}
        `,
      }),
      0,
    ];
  },

  addCommands() {
    return {
      insertBox:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
            content: [{ type: 'paragraph' }],
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },

  parseHTML() {
    return [
      {
        tag: `box`,
      },
    ];
  },
});
