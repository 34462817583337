import Typography from '@tiptap/extension-typography';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TableRow from '@tiptap/extension-table-row';
// @ts-expect-error
import { lowlight } from 'lowlight';
import Focus from '@tiptap/extension-focus';
import Link from '@tiptap/extension-link';

import { CustomTableHeader } from './table-header-extension';
import { CustomOrderedList } from './ordered-list-extesion';
import { CustomBulletList } from './bullet-list-extension';
import { CustomCodeBlock } from './code-block-extension';
import { CustomParagraph } from './pagragraph-extension';
import { CustomTableCell } from './table-cell-extension';
import { CustomListItem } from './list-item-extension';
import { CustomTable } from './table-extension';
import { suggestion } from './suggestion';
import { Commands } from './commands-extension';
import { Columns } from './columns-extension';
import { Heading } from './heading-extension';
import { Image } from './image-extension';
import { Card } from './card-extension';
import { Box } from './box-extension';
import { Callout } from './callout/callout-extension';

export const extensions = [
  StarterKit.configure({
    heading: false,
    paragraph: false,
    bulletList: false,
    orderedList: false,
    dropcursor: {
      width: 2,
      color: '#0891b2',
    },
    horizontalRule: {
      HTMLAttributes: {
        class: 'my-8 text-gray-400',
      },
    },
    codeBlock: false,
    listItem: false,
  }),
  Typography,
  Underline,
  CustomParagraph,
  Link,
  Image,
  CustomListItem.configure({
    HTMLAttributes: {
      class: 'ml-16',
    },
  }),
  CustomTable,
  TableRow.configure({
    HTMLAttributes: {
      class: 'border-b border-gray-200',
    },
  }),
  CustomTableHeader.configure({
    HTMLAttributes: {
      class: 'border-gray-200 bg-gray-50 text-sm font-medium text-gray-800 px-12 py-8 border-r border-gray-200',
    },
  }),
  CustomTableCell.configure({
    HTMLAttributes: {
      class: 'text-sm font-medium text-gray-900 px-12 py-8 border-r border-gray-200',
    },
  }),
  CustomBulletList.configure({
    HTMLAttributes: {
      class: 'list-disc',
    },
  }),
  CustomOrderedList.configure({
    HTMLAttributes: {
      class: 'list-decimal',
    },
  }),
  Heading,
  Columns,
  Box,
  Card,
  CustomCodeBlock.configure({ lowlight }),
  Commands.configure({
    suggestion,
  }),
  Callout,
  Focus,
];
