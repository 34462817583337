import { NodeViewWrapper, NodeViewContent, NodeViewProps } from '@tiptap/react';
import { mergeAttributes } from '@tiptap/core';
import { useSettings } from '../../hooks';
import { Focus } from '../focus-component';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { CalloutsProps, SettingsState } from '../../hocs';
import { CalloutNodeAttributes } from './callout-types';
import { getStyle as getParagraphStyle } from '../paragraph-component';

export const Component: React.FC<NodeViewProps> = (props) => {
  const attributes = mergeAttributes(props.extension.options.HTMLAttributes, props.node.attrs);
  const { settings } = useSettings();
  const calloutStyle = props.node.attrs.calloutStyle as keyof CalloutsProps;
  const calloutSettings = settings?.callouts?.[calloutStyle];
  const paragraphStyle = getParagraphStyle(calloutSettings.p, settings);
  return (
    <NodeViewWrapper className="min-w-full">
      <Focus {...props}>
        <ComponentSkeleton attrs={props.node.attrs as unknown as CalloutNodeAttributes}>
          <NodeViewContent as="p" className={attributes.class} style={paragraphStyle} />
        </ComponentSkeleton>
      </Focus>
    </NodeViewWrapper>
  );
};

export const getBoxStyle = (calloutStyle: keyof CalloutsProps, settings: SettingsState): React.CSSProperties => {
  const calloutSettings = settings?.callouts?.[calloutStyle];
  const typography = settings?.typography.p;
  return {
    marginTop: `${typography.marginTop}rem`,
    marginBottom: `${typography.marginBottom}rem`,
    backgroundColor: calloutSettings?.backgroundColor ? settings?.colors?.[calloutSettings.backgroundColor] : undefined,
    borderRadius: calloutSettings?.borderRadius,
    borderColor: calloutSettings?.borderColor ? settings?.colors?.[calloutSettings.borderColor] : undefined,
  };
};

export const ComponentSkeleton: React.FC<{ attrs: CalloutNodeAttributes; children: any }> = ({ attrs, children }) => {
  const { settings } = useSettings();
  const boxStyle = getBoxStyle(attrs.calloutStyle, settings);
  return (
    <div className="flex min-w-full rounded border border-gray-200 bg-gray-100 px-8 pt-16 mt-16" style={boxStyle}>
      <div className="px-8">
        <InformationCircleIcon className={`w-16 h-16 fill-gray-600`} />
      </div>
      {children}
    </div>
  );
};
