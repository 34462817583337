import { memo } from 'react';
import classnames from 'classnames';

export type IconButtonSize = 'default' | 'small' | 'tiny';

export type IconButtonProps = {
  icon: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  checked?: boolean;
};

export const IconButton: React.FC<IconButtonProps> = memo(({ icon, onClick, checked }) => {
  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    onClick(e);
  };

  return (
    <div
      className={classnames(
        checked ? 'bg-gray-700' : 'bg-black',
        'rounded p-8 bg-black hover:bg-gray-700 cursor-pointer hover:transition-all duration-100'
      )}
      onMouseDown={onMouseDown}
    >
      {icon}
    </div>
  );
});
