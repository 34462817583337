import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { NavigationTreeItem, useNavigationTree, useProject } from '../../hooks';

export const EditorSideNav: React.FC = () => {
  const navigationTree = useNavigationTree();
  const { data: project } = useProject();
  const projectPath = useMemo(() => `/editor/${project!.slug}`, [project!.slug]);

  if (!project) {
    return null;
  }

  return (
    <div className="sticky top-0 self-start max-w-256 h-[100vh]">
      <div className="border-r border-r-gray-200 pr-32 pt-64 mr-64 h-full">
        {navigationTree.map((item) => {
          return (
            <div key={item.id}>
              <TopLevelItem title={item.title} to={`${projectPath}/${item.href}`} />
              <ChildItems projectPath={projectPath} items={item.children} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const TopLevelItem: React.FC<{ title: string; to: string }> = ({ title, to }) => {
  return (
    <div className="mt-8">
      <Link className=" text-gray-600 hover:text-black font-normal" to={to}>
        {title}
      </Link>
    </div>
  );
};

const ChildItem: React.FC<{ projectPath: string; item: NavigationTreeItem }> = ({ projectPath, item }) => {
  return (
    <div>
      <Link className="text-gray-500 hover:text-black font-normal text-sm" to={`${projectPath}/${item.href}` || 'create-new-page'}>
        {item.title}
      </Link>
    </div>
  );
};

const ChildItems: React.FC<{ projectPath: string; items: NavigationTreeItem[] }> = ({ projectPath, items }) => {
  return (
    <div className="pl-12">
      {items.map((item) => {
        return (
          <div key={item.id}>
            <ChildItem projectPath={projectPath} item={item} />
            <ChildItems projectPath={projectPath} items={item.children} />
          </div>
        );
      })}
    </div>
  );
};
