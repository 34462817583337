import classnames from 'classnames';

type Size = 'medium' | 'small' | 'tiny';
type Weight = 'bold' | 'normal' | 'thin';

interface Props {
  size?: Size;
  weight?: Weight;
  secondary?: boolean;
  uppercase?: boolean;
}

const sizeMap: Record<Size, string> = {
  medium: 'text-base',
  small: 'text-sm',
  tiny: 'text-xs',
};

const weightMap: Record<Weight, string> = {
  bold: 'font-semibold',
  normal: 'font-medium',
  thin: 'font-normal',
};

export const Text: React.FC<Props> = ({ children, size = 'medium', weight = 'thin', secondary, uppercase }) => {
  return (
    <span
      className={classnames(
        secondary ? 'text-gray-600' : 'text-gray-800',
        uppercase ? 'uppercase' : '',
        `${sizeMap[size]} ${weightMap[weight]}`
      )}
    >
      {children}
    </span>
  );
};
