import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Editor, Range } from '@tiptap/core';

import { CommandListItem, CommandItem } from '../command-list-item';

interface Props {
  items: CommandItem[];
  editor: Editor;
  range: Range;
  command: (item: CommandItem) => void;
}

interface Ref {
  onKeyDown: (any: any) => boolean;
}

export const CommandList = forwardRef<Ref, Props>((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const selectedRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({
        block: 'nearest',
      });
    }
  }, [selectedRef.current]);

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    props.editor.chain().focus().deleteRange(props.range).run();
    props.editor.commands.uploadImage({ file });
  };

  const selectItem = (index: number) => {
    const item = props.items[index];

    if (!item) {
      return;
    }

    if (item.id === 'img') {
      inputRef.current?.click();
      return;
    }

    props.command(item);
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  if (!props.items.length) {
    return null;
  }

  return (
    <div className="p-2 bg-white rounded-md shadow-md w-384 max-h-256 overflow-scroll">
      {props.items.map((item, index) => {
        return (
          <CommandListItem
            onClick={() => selectItem(index)}
            onMouseOver={() => setSelectedIndex(index)}
            item={item}
            ref={selectedIndex === index ? selectedRef : null}
            key={item.id}
            hover={selectedIndex === index}
          />
        );
      })}
      <input accept="image/*" multiple={false} style={{ display: 'none' }} ref={inputRef} type="file" onChange={handleFileUpload} />
    </div>
  );
});
