import { NodeViewWrapper, NodeViewContent, NodeViewProps } from '@tiptap/react';
import { ImagePlus, Link, Trash } from 'lucide-react';
import { useRef, useState } from 'react';
import classnames from 'classnames';

import { IconButton } from '../components';
import { useMutations } from '../hooks';

export const Component: React.FC<NodeViewProps> = (props) => {
  const { uploadFile } = useMutations();
  const inputRef = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState<string | undefined>(undefined);

  const { image, title } = props.node.attrs;

  const handleFileUpload = async (e: any) => {
    const file = e.target.files[0];
    const img = new Image();
    img.src = window.URL.createObjectURL(file as File);

    setPreview(img.src);

    const res = await uploadFile(file);
    props.updateAttributes({ image: res?.path });
  };

  return (
    <NodeViewWrapper className="min-w-full group rounded overflow-hidden border border-gray-200 h-full relative">
      <div className="flex flex-row w-full flex-wrap" contentEditable={false}>
        {(!!image || !!preview) && (
          <div
            className={classnames(!image && 'opacity-50', 'grow w-full bg-center bg-no-repeat bg-cover')}
            style={{ backgroundImage: `url("${image || preview}")`, height: '170px' }}
          />
        )}
        <div className="p-12 grow">
          <input value={title} onChange={(e) => props.updateAttributes({ title: e.target.value })} className="font-bold text-xl" />
          <p className="text-gray-700 text-base" contentEditable>
            <NodeViewContent className="content" />
          </p>
        </div>
      </div>
      <div className="absolute top-8 right-8 opacity-0 group-hover:opacity-100">
        <IconButton icon={<ImagePlus size={16} color="white" />} onClick={() => inputRef.current?.click()} />
        <IconButton icon={<Link size={16} color="white" />} onClick={() => {}} />
        <IconButton icon={<Trash size={16} color="white" />} onClick={() => props.deleteNode()} />
      </div>
      <input accept="image/*" multiple={false} style={{ display: 'none' }} ref={inputRef} type="file" onChange={handleFileUpload} />
    </NodeViewWrapper>
  );
};
