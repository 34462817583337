export const SET_CODE_BLOCK_PROPS = 'SET_CODE_BLOCK_PROPS';
export const SET_IMAGE_PROPS = 'SET_IMAGE_PROPS';
export const SET_LIST_PROPS = 'SET_LIST_PROPS';
export const SET_CALLOUTS_PROPS = 'SET_CALLOUTS_PROPS';
export const SET_SETTINGS = 'SET_SETTINGS';
export const SET_COLOR = 'SET_COLOR';
export const SET_TYPE = 'SET_TYPE';

export type TypeKey = 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface TypeProps {
  backgroundColor?: string;
  letterSpacing: number;
  marginBottom: number;
  appearance?: 'uppercase' | 'lowercase' | 'capitalize';
  lineHeight: number;
  marginTop: number;
  weight: number;
  color: string;
  font: string;
  size: number;
}

export interface ImagesProps {
  marginBottom: number;
  borderRadius: number;
  marginTop: number;
}

export interface ListsProps {
  marginBottom: number;
  lineHeight: number;
  marginTop: number;
}

export interface CalloutsProps {
  success: CalloutBoxProps;
  information: CalloutBoxProps;
  attention: CalloutBoxProps;
  danger: CalloutBoxProps;
}

export interface CalloutBoxProps {
  p: TypeProps;
  backgroundColor: string;
  borderColor: string;
  borderRadius: number;
  icon: any;
  iconColor: string;
}

export interface CodeBlockProps {
  backgroundColor?: string;
  marginBottom: number;
  borderRadius: number;
  marginTop: number;
  padding: number;
  theme?: string;
}

export interface SettingsState {
  typography: Record<TypeKey, TypeProps>;
  codeBlocks: CodeBlockProps;
  colors: Record<string, string>;
  images: ImagesProps;
  lists: ListsProps;
  callouts: CalloutsProps;
}

export interface SetSettings {
  type: typeof SET_SETTINGS;
  payload: SettingsState;
}

export interface SeImageProps {
  type: typeof SET_IMAGE_PROPS;
  payload: ImagesProps;
}

export interface SetListProps {
  type: typeof SET_LIST_PROPS;
  payload: ListsProps;
}

export interface SetCalloutsProps {
  type: typeof SET_CALLOUTS_PROPS;
  payload: CalloutsProps;
}

export interface SetCodeBlockProps {
  type: typeof SET_CODE_BLOCK_PROPS;
  payload: CodeBlockProps;
}

export interface SetColor {
  type: typeof SET_COLOR;
  payload: {
    key: string;
    value: string;
  };
}

export interface SetType {
  type: typeof SET_TYPE;
  payload: {
    key: string;
    value: TypeProps;
  };
}

export type SettingsAction = SeImageProps | SetColor | SetSettings | SetType | SetListProps | SetCodeBlockProps | SetCalloutsProps;
