interface Props {
  name?: string;
  label?: string;
}

export const FormField: React.FC<Props> = ({ name, label, children }) => {
  return (
    <div className="w-full">
      {label && (
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <div className="mt-4 relative">{children}</div>
    </div>
  );
};
