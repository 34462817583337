import { FieldProps } from 'formik';

import { DropdownOption, Dropdown } from '..';
import { FormField } from '../form-field';

interface Props extends FieldProps {
  name: string;
  label?: string;
  options: DropdownOption[];
  emptyOptionValue?: string;
}

export const DropdownField: React.FC<Props> = ({ form, field, options, emptyOptionValue, label }) => {
  const onSelect = (value: string) => {
    form.setFieldValue(field.name, value === emptyOptionValue ? undefined : value);
  };

  return (
    <FormField label={label}>
      <Dropdown options={options} onSelect={onSelect} selected={field.value || emptyOptionValue} />
    </FormField>
  );
};
