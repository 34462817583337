import { Palette, Type, PlusCircle, Square, MapPin, Image, List, Code2, AlertCircle } from 'lucide-react';
import { ReactNode } from 'react';
import classnames from 'classnames';

interface NavigationItem {
  label: string;
  value: string;
  icon: ReactNode;
}

const navigation: NavigationItem[] = [
  {
    label: 'Color Palette',
    value: 'colors',
    icon: <Palette size={16} />,
  },
  {
    label: 'Typography',
    value: 'typography',
    icon: <Type size={16} />,
  },
  {
    label: 'Lists',
    value: 'lists',
    icon: <List size={16} />,
  },
  {
    label: 'Images',
    value: 'images',
    icon: <Image size={16} />,
  },
  {
    label: 'Code Blocks',
    value: 'code-blocks',
    icon: <Code2 size={16} />,
  },
  {
    label: 'Buttons',
    value: 'buttons',
    icon: <PlusCircle size={16} />,
  },
  {
    label: 'Cards',
    value: 'cards',
    icon: <Square size={16} />,
  },
  {
    label: 'Navigation',
    value: 'navigation',
    icon: <MapPin size={16} />,
  },
  {
    label: 'Callouts',
    value: 'callouts',
    icon: <AlertCircle size={16} />,
  },
];

interface Props {
  selected: string;
  onClick: (value: string) => void;
}

export const SettingsNavigation: React.FC<Props> = ({ selected, onClick }) => {
  return (
    <nav className="flex flex-col gap-4 p-16 py-24 bg-gray-100 h-full">
      {navigation.map((item) => (
        <span
          onClick={() => onClick(item.value)}
          key={item.value}
          className={classnames(
            item.value === selected ? 'bg-gray-400 text-white' : 'text-gray-800 hover:bg-gray-300 hover:bg-opacity-75',
            'group flex items-center py-8 px-12 text-sm font-medium rounded-md cursor-pointer'
          )}
        >
          {item.icon}
          <span className="flex-1 pl-8">{item.label}</span>
        </span>
      ))}
    </nav>
  );
};
