import { Navigate, useParams } from 'react-router-dom';

import { useModal, useProject } from '../../hooks';
import { ModalType } from '../../hocs';
import { Button } from '../button';

export const ProjectPage: React.FC = () => {
  const { showModal } = useModal();
  const { projectSlug } = useParams<{ projectSlug: string }>();
  const { data: project } = useProject();

  const onClickCreatePage = async () => {
    showModal({ type: ModalType.NEW_PAGE, props: {} });
  };

  if (!project?.pages.length) {
    return (
      <div>
        Project has no pages
        <br />
        <Button onClick={onClickCreatePage}>Create New Page</Button>
      </div>
    );
  }

  const defaultPageSlug = project?.pages[0].slug;

  return <Navigate to={`/editor/${projectSlug}/${defaultPageSlug}`} replace />;
};
