import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { Listbox, Transition } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { useState } from 'react';
import classNames from 'classnames';

export interface DropdownOption {
  value: string;
  label: string;
}

interface Props {
  options: DropdownOption[];
  selected?: string | null;
  onSelect: (value: string) => void;
}

export const Dropdown: React.FC<Props> = ({ options, selected, onSelect }) => {
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<any | null>(null);
  const selectedOption = options.find((option) => option.value === selected);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          padding: 8,
        },
      },
    ],
  });

  return (
    <Listbox value={selected} onChange={onSelect}>
      {({ open }) => (
        <div className="relative">
          <Listbox.Button
            ref={setReferenceElement}
            className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pr-24 pl-12 p-8 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
          >
            <span className="block truncate text-gray-800">{selectedOption?.label}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
              <SelectorIcon className="h-16 w-16 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition show={open} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className="min-w-[150px] z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
            >
              {options.map((option) => (
                <Listbox.Option
                  key={option.value}
                  className={({ active }) =>
                    classNames(active ? 'text-white bg-gray-600' : 'text-gray-900', 'cursor-default select-none relative py-8 pl-8 pr-16')
                  }
                  value={option.value}
                >
                  {({ selected, active }) => (
                    <>
                      <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>{option.label}</span>
                      {selected ? (
                        <span
                          className={classNames(
                            active ? 'text-white' : 'text-gray-600',
                            'absolute inset-y-0 right-0 flex items-center pr-8'
                          )}
                        >
                          <CheckIcon className="h-16 w-16" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};
