import axios from './axios';

export type OrderParams<T> = {
  [P in keyof T]?: 1 | -1;
};

export type SearchParams<T> = {
  query?: Partial<T>;
  limit?: number;
  offset?: number;
  include?: string[];
  order?: OrderParams<T>;
};

export type GetParams = {
  include?: string[];
};

export type Paginated<T> = {
  total: number;
  limit: number;
  skip: number;
  data: T[];
};

export class RestfulService<T> {
  path: string;
  constructor(path: string) {
    this.path = path;
  }

  async get(id: string, params: GetParams = {}): Promise<T> {
    const { include: $include } = params;
    return axios.get(`${this.path}/${id}`, {
      params: {
        ...($include && { $include }),
      },
    });
  }

  async getOne(query: Partial<T>, include?: string[]): Promise<T> {
    const results = await this.find({ limit: 1, query, include });
    if (results.total === 0) {
      throw Error(`Not found`);
    }
    return results.data[0];
  }

  async post(resource: Partial<T>): Promise<T> {
    const { data } = await axios.post<T>(this.path, resource);
    return data;
  }

  async patch(id: string, resource: Partial<T>): Promise<Partial<T>> {
    const { data } = await axios.patch<T>(`${this.path}/${id}`, resource);
    return data;
  }

  async delete(id: string): Promise<T> {
    const { data } = await axios.delete(`${this.path}/${id}`);
    return data;
  }

  async find(params: SearchParams<T> = { query: {} }, include?: string[]): Promise<Paginated<T>> {
    const { limit: $limit, offset: $skip, order: $sort, include: $include, query } = params;
    const { data } = await axios.get<Paginated<T>>(this.path, {
      params: {
        ...($skip && { $skip }),
        ...($limit && { $limit }),
        ...($sort && { $sort }),
        ...($include && { $include: $include.join(',') }),
        ...query,
      },
    });
    return data;
  }
}
