import { mergeAttributes } from '@tiptap/core';
import TableHeader from '@tiptap/extension-table-header';

export const CustomTableHeader = TableHeader.extend({
  content: 'inline*',
  addAttributes() {
    return {
      align: {
        default: 'left',
        parseHTML: (element: HTMLElement) => element.getAttribute('align'),
        renderHTML: (attributes: Record<string, any>) => {
          if (!attributes.align) {
            return {};
          }

          return {
            align: attributes.align,
          };
        },
      },
    };
  },

  renderHTML({ HTMLAttributes, node }) {
    return ['th', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, { class: `text-${node.attrs.align}` }), 0];
  },
});
