import { SettingsState } from '../hocs';

interface Color {
  value: keyof SettingsState['colors'];
  label?: string;
}

export const bgAndTextColors: Color[] = [
  {
    value: 'background1',
    label: 'Background',
  },
  {
    value: 'background2',
    label: 'Secondary Background',
  },
  {
    value: 'background3',
    label: 'Tertiary Background',
  },
  {
    value: 'text3',
    label: 'Tertiary Text',
  },
  {
    value: 'text2',
    label: 'Secondary Text',
  },
  {
    value: 'text1',
    label: 'Text',
  },
];

export const primaryColors: Color[] = [
  {
    value: 'primary6',
  },
  {
    value: 'primary5',
  },
  {
    value: 'primary4',
  },
  {
    value: 'primary3',
  },
  {
    value: 'primary2',
  },
  {
    value: 'primary1',
  },
];

export const secondaryColors: Color[] = [
  {
    value: 'secondary6',
  },
  {
    value: 'secondary5',
  },
  {
    value: 'secondary4',
  },
  {
    value: 'secondary3',
  },
  {
    value: 'secondary2',
  },
  {
    value: 'secondary1',
  },
];

export const tertiaryColors: Color[] = [
  {
    value: 'tertiary6',
  },
  {
    value: 'tertiary5',
  },
  {
    value: 'tertiary4',
  },
  {
    value: 'tertiary3',
  },
  {
    value: 'tertiary2',
  },
  {
    value: 'tertiary1',
  },
];

export const infoColors: Color[] = [
  {
    value: 'success2',
    label: 'Success Background',
  },
  {
    value: 'warning2',
    label: 'Warning Background',
  },
  {
    value: 'danger2',
    label: 'Danger Background',
  },
  {
    value: 'success1',
    label: 'Success Text',
  },
  {
    value: 'warning1',
    label: 'Warning Text',
  },
  {
    value: 'danger1',
    label: 'Danger Text',
  },
];
