import { bgAndTextColors, infoColors, primaryColors, secondaryColors, tertiaryColors } from '../../constants';
import { Text, ColorPicker } from '..';
import { useSettings } from '../../hooks';

export const SettingsColorPalette: React.FC = () => {
  const { settings, setColor } = useSettings();

  return (
    <div className="flex gap-4 flex-col">
      <Text secondary uppercase size="tiny" weight="normal">
        Backgrounds & Text
      </Text>
      <div className="flex rounded-md flex-0 mb-16">
        {bgAndTextColors.map((color) => (
          <ColorPicker
            onChange={(value) => {
              setColor(color.value, value);
            }}
            value={settings.colors[color.value]}
            key={color.value}
          />
        ))}
      </div>
      <Text secondary uppercase size="tiny" weight="normal">
        Primary & Action
      </Text>
      <div className="flex rounded-md flex-0 mb-16">
        {primaryColors.map((color) => (
          <ColorPicker
            onChange={(value) => {
              setColor(color.value, value);
            }}
            value={settings.colors[color.value]}
            key={color.value}
          />
        ))}
      </div>
      <Text secondary uppercase size="tiny" weight="normal">
        Secondary & Other
      </Text>
      <div className="flex rounded-md flex-0">
        {secondaryColors.map((color) => (
          <ColorPicker
            onChange={(value) => {
              setColor(color.value, value);
            }}
            value={settings.colors[color.value]}
            key={color.value}
          />
        ))}
      </div>
      <div className="flex rounded-md flex-0 mb-16">
        {tertiaryColors.map((color) => (
          <ColorPicker
            onChange={(value) => {
              setColor(color.value, value);
            }}
            value={settings.colors[color.value]}
            key={color.value}
          />
        ))}
      </div>
      <Text secondary uppercase size="tiny" weight="normal">
        Info & Labels
      </Text>
      <div className="flex rounded-md flex-0 mb-16">
        {infoColors.map((color) => (
          <ColorPicker
            onChange={(value) => {
              setColor(color.value, value);
            }}
            value={settings.colors[color.value]}
            key={color.value}
          />
        ))}
      </div>
    </div>
  );
};
