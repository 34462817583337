import { Layout, RangeInput, Cell } from '..';
import { useSettings } from '../../hooks';

export const SettingsImages: React.FC = () => {
  const { settings, setImageProps } = useSettings();

  return (
    <Layout>
      <Cell direction="row">
        <RangeInput
          min={0}
          max={5}
          step={0.1}
          label="Margin Top"
          value={settings.images?.marginTop}
          onChange={(value) => {
            setImageProps({
              ...settings.images,
              marginTop: value,
            });
          }}
        />
        <RangeInput
          min={0}
          max={5}
          step={0.1}
          label="Margin Bottom"
          value={settings.images?.marginBottom}
          onChange={(value) => {
            setImageProps({
              ...settings.images,
              marginBottom: value,
            });
          }}
        />
      </Cell>
      <Cell direction="row">
        <RangeInput
          min={0}
          max={16}
          step={1}
          label="Border Radius"
          value={settings.images?.borderRadius}
          onChange={(value) => {
            setImageProps({
              ...settings.images,
              borderRadius: value,
            });
          }}
        />
      </Cell>
    </Layout>
  );
};
