import { useReducer, createContext, memo } from 'react';
import { noop } from 'lodash';

import { SettingsAction, SettingsState } from './types';
import { initialState } from './initial-state';
import { reducer } from './reducer';

interface ContextProps {
  state: SettingsState;
  dispatch: React.Dispatch<SettingsAction>;
}

interface Props {
  initialState?: SettingsState;
}

const initialContextState: ContextProps = {
  state: initialState,
  dispatch: noop,
};

export const SettingsContext = createContext<ContextProps>(initialContextState);

export const SettingsContextProvider: React.FC<Props> = memo((props) => {
  const [state, dispatch] = useReducer(reducer, props.initialState || initialState);

  const contextValue: ContextProps = {
    state,
    dispatch,
  };

  return <SettingsContext.Provider value={contextValue}>{props.children}</SettingsContext.Provider>;
});
