import { PublisherRequest } from '../types';
import axios from './axios';

export class PublisherService {
  // Publishes project on the provided slug
  async publish({ projectId, slug }: { projectId: string; slug: string }): Promise<void> {
    await axios.post<void>('publisher', {
      action: 'publish',
      projectId,
      slug,
    } as PublisherRequest);
  }

  // This is going to change to require only slug
  async unpublish({ projectId, slug }: { projectId: string; slug: string }): Promise<void> {
    await axios.post<void>('publisher', {
      action: 'unpublish',
      projectId,
      slug,
    } as PublisherRequest);
  }
}
