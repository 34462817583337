import { GithubApp, NavigationItem, Page, Project, User } from '../types';
import { AuthService } from './auth-service';
import { FileUploadsService } from './file-uploads-service';
import { PublisherService } from './publisher-service';
import { RestfulService } from './restful-service';

export const projectsService = new RestfulService<Project>('projects');
export const pagesService = new RestfulService<Page>('pages');
export const filesUploadsService = new FileUploadsService();
export const githubAppsService = new RestfulService<GithubApp>('github-apps');
export const navigationItemsService = new RestfulService<NavigationItem>('navigation-items');
export const authService = new AuthService();
export const usersService = new RestfulService<User>('users');
export const publisherService = new PublisherService();
