import { ArrowLeft, Edit3, MoreHorizontal, Plus, Rocket, Settings } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import { Popover, Transition } from '@headlessui/react';

import { useModal, useMutations, useProject } from '../../hooks';
import { IconButton } from '../icon-button';
import { ModalType } from '../../hocs';
import { Dropdown } from '..';
import { useCallback, useMemo } from 'react';

interface Action {
  label: string;
  icon: React.ReactNode;
  onClick: () => void;
}

export const EditorFloatingMenu: React.FC = () => {
  const { projectSlug, '*': pageSlug } = useParams<{ projectSlug: string; '*': string }>();
  const { publishProject, unpublishProject } = useMutations();
  const { data: project } = useProject();
  const { showModal } = useModal();
  const navigate = useNavigate();

  const pageOptions = project?.pages.map((item) => ({ value: item.slug, label: item.title })) || [];

  const onClickPublishOrUnpublish = useCallback(() => {
    if (project) {
      const params = { id: project.id, slug: project.slug };
      if (project.isPublished) {
        unpublishProject.mutate(params);
      } else {
        publishProject.mutate(params);
      }
    }
  }, [project]);

  const actions: Action[] = useMemo(() => {
    if (project) {
      return [
        {
          label: project.isPublished ? 'Unpublish' : 'Publish',
          icon: <Rocket size={16} />,
          onClick: onClickPublishOrUnpublish,
        },
        {
          label: 'Edit Site Settings',
          icon: <Settings size={16} />,
          onClick: () => showModal({ type: ModalType.SETTINGS, props: {} }),
        },
        {
          label: 'Back to Dashboard',
          icon: <ArrowLeft size={16} />,
          onClick: () => navigate('/dashboard'),
        },
      ];
    } else {
      return [];
    }
  }, [project, onClickPublishOrUnpublish]);

  const onSelectPage = (pageSlug: string) => {
    navigate(`/editor/${projectSlug}/${pageSlug}`);
  };

  return (
    <Popover className="z-20 relative">
      {({ open }) => (
        <div className="fixed bottom-48 right-48">
          <Popover.Button className="cursor-pointer shadow-md p-16  bg-gray-400 rounded-full text-white hover:bg-gray-500 hover:shadow-lg">
            <MoreHorizontal size={16} />
          </Popover.Button>

          <Transition
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
          >
            <Popover.Panel className="absolute bottom-64 right-0 z-10 py-16 px-4 rounded-md transform shadow-lg bg-white">
              <h3 className="text-xs px-12 font-medium tracking-wide text-gray-500 uppercase pb-4">Page</h3>
              <div className="flex gap-8 w-256 pb-16 px-12">
                <div className="flex-1">
                  <Dropdown options={pageOptions} onSelect={onSelectPage} selected={pageSlug} />
                </div>
                <IconButton
                  onClick={() => {
                    showModal({ type: ModalType.NEW_PAGE, props: {} });
                  }}
                  icon={<Plus color="white" size={16} />}
                />
                <IconButton
                  onClick={() => {
                    showModal({ type: ModalType.NEW_PAGE, props: {} });
                  }}
                  icon={<Edit3 color="white" size={16} />}
                />
              </div>
              <h3 className="text-xs px-12 font-medium tracking-wide text-gray-500 uppercase pb-4">Actions</h3>
              <nav>
                {actions.map((action) => (
                  <span
                    onClick={action.onClick}
                    key={action.label}
                    className={
                      'group text-gray-700 hover:bg-gray-100 hover:bg-opacity-75 flex items-center p-12 text-sm font-medium rounded-md cursor-pointer'
                    }
                  >
                    {action.icon}
                    <span className="flex-1 pl-8">{action.label}</span>
                  </span>
                ))}
              </nav>
            </Popover.Panel>
          </Transition>
        </div>
      )}
    </Popover>
  );
};
