interface Theme {
  label: string;
  value: string;
}

export const hljsThemes: Theme[] = [
  {
    label: 'A11Y Dark',
    value: 'a11y-dark.min.css',
  },
  {
    label: 'A11Y Light',
    value: 'a11y-light.min.css',
  },
  {
    label: 'Atom One Dark',
    value: 'atom-one-dark.min.css',
  },
  {
    label: 'Atom One Light',
    value: 'atom-one-light.min.css',
  },
  {
    label: 'Github Dark',
    value: 'github-dark.min.css',
  },
  {
    label: 'Github',
    value: 'github.min.css',
  },
  {
    label: 'Monokai',
    value: 'monokai.min.css',
  },
  {
    label: 'Nord',
    value: 'nord.min.css',
  },
  {
    label: 'Rainbow',
    value: 'rainbow.min.css',
  },
  {
    label: 'School Book',
    value: 'school-book.min.css',
  },
  {
    label: 'Tokyo Night Dark',
    value: 'tokyo-night-dark.min.css',
  },
];
