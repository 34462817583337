import classnames from 'classnames';
import { AlertCircle } from 'lucide-react';

interface Props {
  type?: string;
  name?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  required?: boolean;
  onChange: (e: React.ChangeEvent<any>) => void;
  placeholder?: string;
  value: string;
  error?: string | null;
}

export const Input: React.FC<Props> = (props) => {
  return (
    <div>
      <div className="relative">
        <input
          autoComplete={props.autoComplete}
          placeholder={props.placeholder}
          autoFocus={props.autoFocus}
          className={classnames(
            props.error
              ? 'text-danger border-danger focus:border-danger focus:ring-danger'
              : 'border-gray-300 text-gray-800 focus:border-gray-500 focus:ring-gray-500',
            'shadow-sm  block w-full sm:text-sm  rounded-md py-8 px-12'
          )}
          required={props.required}
          onChange={props.onChange}
          value={props.value}
          type={props.type || 'text'}
          name={props.name}
        />
        {props.error && (
          <div className="absolute inset-y-0 right-0 pr-12 flex items-center pointer-events-none">
            <AlertCircle className="h-16 w-16 text-danger" aria-hidden="true" />
          </div>
        )}
      </div>
      {props.error && <p className="mt-4 text-sm text-danger">{props.error}</p>}
    </div>
  );
};
