const TYPE_ATTRIBUTE_NAME = 'data-element-type';

export const parseByElementType = (type: string) => [
  {
    tag: 'div',
    getAttrs: (el: any) => (el as HTMLDivElement).getAttribute(TYPE_ATTRIBUTE_NAME) === type && {},
  },
];

export const elementTypeAttribute = (type: string): Record<string, string> => ({ [TYPE_ATTRIBUTE_NAME]: type });
