import { useMutation, useQueryClient } from 'react-query';
import { JSONContent } from '@tiptap/react';
import { v4 as uuid } from 'uuid';

import { NavigationItem, Page, Project } from '../types';
import { useProject } from './useProject';
import {
  navigationItemsService,
  filesUploadsService,
  publisherService,
  projectsService,
  pagesService,
  usersService,
  authService,
} from '../portal-api-client';

interface AuthProps {
  email: string;
  password: string;
}

interface SavePageProps {
  id: string;
  value: JSONContent;
}

interface PublishProjectProps {
  id: string;
  slug: string;
}

interface UnpublishProjectProps extends PublishProjectProps {}

interface CreatePageProps {
  title: string;
  navigationItemParentId?: string;
  slug: string;
}

export const useMutations = () => {
  const queryClient = useQueryClient();
  const { data: project } = useProject();

  const login = useMutation(
    ({ email, password }: AuthProps) => {
      return authService.authenticate({
        strategy: 'local',
        email,
        password,
      });
    },
    {
      onSuccess: (result) => {
        window.localStorage.setItem('token', result.accessToken);
        queryClient.setQueryData('user', () => result.user);
      },
    }
  );

  const signup = useMutation(
    ({ email, password }: AuthProps) => {
      return usersService.post({ email, password });
    },
    {
      onSuccess: (result) => {
        queryClient.setQueryData('user', () => result);
      },
    }
  );

  const createProject = useMutation(
    () => {
      return projectsService.post({
        title: "Rock'n'roll docs example",
        slug: `project-${uuid().substring(0, 5)}`,
      });
    },
    {
      onSuccess: (result) => {
        queryClient.setQueryData('projects', (old?: Project[]) => {
          return [...(old || []), result];
        });
      },
    }
  );

  const publishProject = useMutation(
    ({ id, slug }: PublishProjectProps) => {
      return publisherService.publish({
        projectId: id,
        slug,
      });
    },
    {
      onSuccess: (result, variables) => {
        queryClient.invalidateQueries(['project', variables.slug]);
      },
    }
  );

  const unpublishProject = useMutation(
    ({ id, slug }: UnpublishProjectProps) => {
      return publisherService.unpublish({
        projectId: id,
        slug,
      });
    },
    {
      onSuccess: (result, variables) => {
        queryClient.invalidateQueries(['project', variables.slug]);
      },
    }
  );

  const removeProject = useMutation(
    (id: string) => {
      return projectsService.delete(id);
    },
    {
      onSuccess: (result) => {
        queryClient.setQueryData('projects', (old?: Project[]) => {
          return old?.filter((project) => project.id !== result.id) || [];
        });
      },
    }
  );

  const makeEmptyPage = (title: string): JSONContent => {
    return {
      type: 'doc',
      content: [
        {
          type: 'heading',
          attrs: {
            level: 1,
          },
          content: [
            {
              type: 'text',
              text: title,
            },
          ],
        },
      ],
    };
  };

  const createPage = useMutation(
    ({ title, navigationItemParentId, slug }: CreatePageProps) => {
      return Promise.all([
        pagesService.post({
          title,
          slug: slug,
          sourceFile: JSON.stringify({ value: makeEmptyPage(title) }),
          isPublished: false,
          projectId: project!.id,
        }),
        navigationItemsService.post({
          title,
          href: slug,
          parentId: navigationItemParentId,
          sortingWeight: 0,
          projectId: project!.id,
        }),
      ]);
    },
    {
      onSuccess: (result) => {
        queryClient.setQueryData(['pages', project!.id], (old?: Page[]) => {
          return [...(old || []), result[0]];
        });
        queryClient.setQueryData(['navigation-items', project!.id], (old?: NavigationItem[]) => {
          return [...(old || []), result[1]];
        });
      },
    }
  );

  const savePage = useMutation(async ({ id, value }: SavePageProps) => {
    return pagesService.patch(id, { sourceFile: JSON.stringify({ value }) });
  });

  const uploadFile = async (file: File) => {
    try {
      const { signedUrl, path } = await filesUploadsService.createSignedUrl({
        projectId: project!.id,
        fileName: file.name,
      });

      await fetch(signedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file,
      });

      return { path, signedUrl };
    } catch (e) {
      console.error('failed to update page');
      console.error(e);
    }
  };

  return {
    unpublishProject,
    publishProject,
    createProject,
    removeProject,
    uploadFile,
    createPage,
    savePage,
    signup,
    login,
  };
};
