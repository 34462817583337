import { SettingsState, TypeKey, TypeProps } from './types';

const typography = {
  p: {
    font: 'Helvetica Neue',
    color: 'text1',
    weight: 400,
    marginTop: 0,
    marginBottom: 1,
    size: 1,
    letterSpacing: 1,
    lineHeight: 1.6,
  },
  h1: {
    font: 'Helvetica Neue',
    color: 'text1',
    weight: 700,
    marginTop: 0,
    marginBottom: 0,
    size: 3,
    letterSpacing: 3,
    lineHeight: 1,
  },
  h2: {
    font: 'Helvetica Neue',
    color: 'danger1',
    weight: 500,
    marginTop: 0,
    marginBottom: 1,
    size: 1,
    letterSpacing: 0.5,
    lineHeight: 1.3,
    appearance: 'uppercase',
    backgroundColor: 'danger2',
  },
  h3: {
    font: 'Helvetica Neue',
    color: 'text1',
    weight: 600,
    marginTop: 0,
    marginBottom: 0,
    size: 2,
    letterSpacing: 1,
    lineHeight: 1.3,
  },
  h4: {
    font: 'Helvetica Neue',
    color: 'text1',
    weight: 500,
    marginTop: 0,
    marginBottom: 0,
    size: 1.5,
    letterSpacing: 1,
    lineHeight: 1.3,
  },
  h5: {
    font: 'Helvetica Neue',
    color: 'text1',
    weight: 500,
    marginTop: 0,
    marginBottom: 0,
    size: 1.25,
    letterSpacing: 1,
    lineHeight: 1.6,
  },
  h6: {
    font: 'Helvetica Neue',
    color: 'text1',
    weight: 500,
    marginTop: 0,
    marginBottom: 0,
    size: 1,
    letterSpacing: 1,
    lineHeight: 1.3,
  },
} as Record<TypeKey, TypeProps>;

const callouts = {
  success: {
    p: { ...typography.p, color: 'success' },
    backgroundColor: 'success2',
    borderColor: '#fff',
    borderRadius: 3,
    icon: 'check-circle-2',
    iconColor: '#33ff33',
  },
  information: {
    p: { ...typography.p, color: 'text' },
    backgroundColor: 'background',
    borderColor: '#fff',
    borderRadius: 3,
    icon: 'check-circle-2',
    iconColor: '#33ff33',
  },
  attention: {
    p: { ...typography.p, color: 'warning' },
    backgroundColor: 'warning2',
    borderColor: '#fff',
    borderRadius: 3,
    icon: 'check-circle-2',
    iconColor: '#33ff33',
  },
  danger: {
    p: { ...typography.p, color: 'danger' },
    backgroundColor: 'danger2',
    borderColor: '#fff',
    borderRadius: 3,
    icon: 'check-circle-2',
    iconColor: '#33ff33',
  },
};

export const initialState: SettingsState = {
  typography,
  callouts,
  colors: {
    background1: '#f8fafc',
    background2: '#e2e8f0',
    background3: '#94a3b8',
    text1: '#0f172a',
    text2: '#334155',
    text3: '#64748b',

    primary1: '#0369a1',
    primary2: '#0284c7',
    primary3: '#0ea5e9',
    primary4: '#38bdf8',
    primary5: '#bae6fd',
    primary6: '#f0f9ff',

    secondary1: '#581c87',
    secondary2: '#7e22ce',
    secondary3: '#a855f7',
    secondary4: '#c084fc',
    secondary5: '#e9d5ff',
    secondar6: '#faf5ff',

    tertiary1: '#365314',
    tertiary2: '#4d7c0f',
    tertiary3: '#84cc16',
    tertiary4: '#a3e635',
    tertiary5: '#d9f99d',
    tertiary6: '#f7fee7',

    success1: '#0d9488',
    success2: '#ccfbf1',
    warning1: '#facc15',
    warning2: '#fef9c3',
    danger1: '#dc2626',
    danger2: '#fee2e2',
  },
  images: {
    marginTop: 1,
    marginBottom: 1,
    borderRadius: 0,
  },
  lists: {
    marginTop: 0,
    marginBottom: 1,
    lineHeight: 1,
  },
  codeBlocks: {
    theme: 'github-dark.min.css',
    marginTop: 0,
    marginBottom: 1,
    borderRadius: 1,
    padding: 1,
  },
};
