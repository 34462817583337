import axios from 'axios';
import qs from 'qs';

const axiosInstance = axios.create({
  baseURL: 'https://api.portal.dev',
  timeout: 5000,
});

axiosInstance.interceptors.request.use((config) => {
  config.headers = {
    authorization: localStorage.getItem('token')!,
  };
  config.paramsSerializer = (params) => {
    return qs.stringify(params);
  };
  return config;
});

export default axiosInstance;
