import { NodeViewWrapper, NodeViewContent, NodeViewProps } from '@tiptap/react';
import { Node as ProseMirrorNode } from 'prosemirror-model';
import { useState } from 'react';

import { CardEditor, Slideover } from '../components';
import { Focus } from './focus-component';

export const Component: React.FC<NodeViewProps> = (props) => {
  const { node, editor } = props;
  const [showSettings, setShowSettings] = useState(false);

  const hideSettings = () => {
    setShowSettings(false);
  };

  const onClickSettings = () => {
    setShowSettings(true);
  };

  const pos = props.getPos();

  console.log({ pos });

  const updateCards = (content: any) => {
    // const newNode: ProseMirrorNode = {
    //   ...node,
    //   content: {
    //     ...node.content,
    //     // @ts-ignore
    //     content,
    //   },
    // };
    const cardList = editor.schema.nodes.columns.create(null, content);
    // const transaction = editor.state.tr.replaceSelectionWith(node);
    const transaction = editor.state.tr.replaceWith(pos, pos + node.nodeSize, cardList);
    editor.view.dispatch(transaction);
  };

  return (
    <NodeViewWrapper className="flex w-full pt-16 pb-16">
      <Focus {...props}>
        <NodeViewContent className="flex flex-1 columns-node-view" />
      </Focus>
      <Slideover show={showSettings} onClose={hideSettings}>
        {/* @ts-ignore */}
        <CardEditor editor={editor} cards={node.content.content} updateCards={updateCards} />
      </Slideover>
    </NodeViewWrapper>
  );
};
