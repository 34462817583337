import React, { useCallback, useMemo, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { noop } from 'lodash';

import { SettingsModalType, SettingsModal, NewPageModal, NewPageModalType } from '../../components';
import { useParams } from 'react-router-dom';

export enum ModalType {
  SETTINGS = 'settings',
  NEW_PAGE = 'new-page',
}

type Modal = SettingsModalType | NewPageModalType;

interface IModalContext {
  modal: Modal | null;
  showModal(modal: Modal): void;
  hideModal(): void;
}

const initialValues: IModalContext = {
  modal: null,
  showModal: noop,
  hideModal: noop,
};

export const ModalContext = React.createContext<IModalContext>(initialValues);

export const ModalProvider: React.FC = (props) => {
  const [modal, setModal] = useState<Modal | null>(null);
  const params = useParams();

  const showModal = useCallback(
    (m: Modal) => {
      setModal(m);
    },
    [setModal]
  );

  const hideModal = useCallback(() => {
    setModal(null);
  }, [setModal]);

  const renderModal = useCallback(() => {
    if (!modal) {
      return null;
    }

    switch (modal.type) {
      case ModalType.SETTINGS: {
        return <SettingsModal {...modal.props} />;
      }

      case ModalType.NEW_PAGE: {
        return <NewPageModal {...modal.props} />;
      }

      default: {
        return null;
      }
    }
  }, [modal, params]);

  const value = useMemo(
    () => ({
      modal,
      showModal,
      hideModal,
    }),
    [modal, showModal, hideModal]
  );

  return (
    <ModalContext.Provider value={value}>
      {props.children}
      <Transition.Root show={!!modal}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={hideModal}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="flex justify-center items-center h-screen">{renderModal()}</div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </ModalContext.Provider>
  );
};
