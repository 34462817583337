import { Formik } from 'formik';

import { PageFormValues, validatePageForm } from '../page-form';
import { useMutations, useModal } from '../../hooks';
import { Modal, PageForm } from '..';
import { ModalType } from '../../hocs';

export interface NewPageModalType {
  type: ModalType.NEW_PAGE;
  props: any;
}

export const NewPageModal: React.FC = () => {
  const { createPage } = useMutations();
  const { hideModal } = useModal();

  const handleSubmit = async (values: PageFormValues) => {
    createPage.mutate(values, {
      onSuccess: () => {
        hideModal();
      },
    });
  };

  return (
    <Formik validate={validatePageForm} initialValues={{ title: '', navigationItemParentId: undefined, slug: '' }} onSubmit={handleSubmit}>
      {({ submitForm }) => (
        <Modal loading={createPage.isLoading} title="New Page" primaryButtonText="Create Page" primaryButtonOnClick={submitForm}>
          <PageForm />
        </Modal>
      )}
    </Formik>
  );
};
