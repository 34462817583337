interface Action {
  label: string;
  onClick: () => void;
}

interface Props {
  actions?: Action[];
}

export const Card: React.FC<Props> = ({ children, actions }) => {
  return (
    <div className="bg-white shadow rounded-md w-full divide-y divide-gray-200">
      <div className="p-16">{children}</div>
      {!!actions?.length && (
        <div className="-mt-px flex divide-x divide-gray-200">
          {actions.map((action) => (
            <div className="w-0 flex-1 flex">
              <button
                onClick={action.onClick}
                className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
              >
                <span className="ml-3">{action.label}</span>
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
