import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import classnames from 'classnames';

import { useModal } from '../../hooks';
import { Button } from '..';

interface Props {
  title: string;
  primaryButtonText?: string;
  primaryButtonOnClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  noPadding?: boolean;
  width?: string;
  height?: string;
}

export const Modal: React.FC<Props> = ({
  primaryButtonOnClick,
  primaryButtonText,
  noPadding,
  children,
  disabled,
  loading,
  title,
  height = 'auto',
  width = 'auto',
}) => {
  const { hideModal } = useModal();

  return (
    <div
      style={{ width, height }}
      className={classnames(
        noPadding ? '' : 'p-16',
        'relative inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all overflow-hidden'
      )}
    >
      <div className="hidden sm:block absolute top-0 right-0 pt-16 pr-16">
        <button
          type="button"
          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          onClick={hideModal}
        >
          <XIcon className="h-16 w-16" aria-hidden="true" />
        </button>
      </div>
      {noPadding ? (
        <>{children}</>
      ) : (
        <div className="sm:flex sm:items-start w-full">
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {title}
            </Dialog.Title>
            <div className="mt-8">{children}</div>
          </div>
        </div>
      )}
      {primaryButtonText && (
        <div className="mt-16 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <Button disabled={disabled} loading={loading} onClick={primaryButtonOnClick}>
            {primaryButtonText}
          </Button>
        </div>
      )}
    </div>
  );
};
