import { ReactNodeViewRenderer } from '@tiptap/react';
import { mergeAttributes } from '@tiptap/react';
import { Node } from '@tiptap/core';

import { Component } from './card-component';

import { attribute } from './attribute';
import { elementTypeAttribute, parseByElementType } from './utils';

export interface CardOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    card: {
      createCard: (attributes: any) => ReturnType;
    };
  }
}

export const Card = Node.create<CardOptions>({
  name: 'card',
  content: 'inline*',
  group: 'block',
  marks: '',
  isolating: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      title: attribute('title', ''),
      image: attribute('image', ''),
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, elementTypeAttribute('card')), 0];
  },

  parseHTML() {
    return parseByElementType('card');
  },

  addCommands() {
    return {
      createCard:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
            content: [{ type: 'paragraph' }],
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },
});
