import { Bookmark, Code2, Image, List, ListOrdered, LayoutGrid, Table } from 'lucide-react';
import { CommandItem } from '../components';

export const commands: CommandItem[] = [
  {
    id: 'h1',
    title: 'Page Title',
    subtitle: 'The name of your page.',
    icon: <Bookmark size={16} />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setNode('heading', { level: 1 }).run();
    },
  },
  {
    id: 'h2',
    title: 'Page Subtitle',
    subtitle: 'Describe your page.',
    icon: <Bookmark size={16} />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setNode('heading', { level: 2 }).run();
    },
  },
  {
    id: 'h3',
    title: 'Section Title',
    subtitle: 'Name your sections. Stay Organized.',
    icon: <Bookmark size={16} />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setNode('heading', { level: 3 }).run();
    },
  },
  {
    id: 'h4',
    title: 'Heading 4',
    subtitle: 'A small heading.',
    icon: <Bookmark size={16} />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setNode('heading', { level: 4 }).run();
    },
  },
  {
    id: 'h5',
    title: 'Heading 5',
    subtitle: 'An even smaller heading.',
    icon: <Bookmark size={16} />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setNode('heading', { level: 5 }).run();
    },
  },
  {
    id: 'h6',
    title: 'Heading 6',
    subtitle: 'The smallest of headings.',
    icon: <Bookmark size={16} />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setNode('heading', { level: 6 }).run();
    },
  },
  {
    id: 'p',
    title: 'Paragraph',
    subtitle: 'Just some good ol regular text.',
    icon: <Bookmark size={16} />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setNode('paragraph').run();
    },
  },
  {
    id: 'ul',
    title: 'Bullet List',
    subtitle: 'A list with dots on the side.',
    icon: <List size={16} />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).toggleBulletList().run();
    },
  },
  {
    id: 'ol',
    title: 'Ordered List',
    subtitle: 'A list with some numbers.',
    icon: <ListOrdered size={16} />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).toggleOrderedList().run();
    },
  },
  {
    id: 'code',
    title: 'Code Block',
    subtitle: 'A big blok of code.',
    icon: <Code2 size={16} />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).toggleCodeBlock().run();
    },
  },
  {
    id: 'cards',
    title: 'Card List',
    subtitle: `Dude, where're my cards?`,
    icon: <LayoutGrid size={16} />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).createCardList({}).run();
    },
  },
  {
    id: 'img',
    title: 'Image',
    subtitle: 'A website looks better with images.',
    icon: <Image size={16} />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).toggleCodeBlock().run();
    },
  },
  {
    id: 'table',
    title: 'Table',
    subtitle: 'Make things tabular.',
    icon: <Table size={16} />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).insertTable().run();
    },
  },
  {
    id: 'callout',
    title: 'Callout',
    subtitle: 'Make readers notice.',
    icon: <Bookmark size={16} />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setNode('callout').run();
    },
  },
];
