import { Navigate } from 'react-router-dom';
import { useUser } from '../../hooks';

export const RequireAuth: React.FC = ({ children }) => {
  const userQuery = useUser();

  if (!userQuery.data && !userQuery.isLoading) {
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
};
