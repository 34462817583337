import { ReactNodeViewRenderer } from '@tiptap/react';
import OrderedList from '@tiptap/extension-ordered-list';

import { Component } from './list-component';

export const CustomOrderedList = OrderedList.extend({
  draggable: true,
  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },
});
