import { useEffect, useState } from 'react';
import { JSONContent } from '@tiptap/react';
import { Helmet } from 'react-helmet';

import { Page, Project } from '../../types';
import { SettingsState } from '../../hocs';
import { EditorContent } from '..';
import { useSettings } from '../../hooks';

interface Props {
  project: Project;
  page: Page;
}

export const Editor: React.FC<Props> = ({ page, project }) => {
  const [title, setTitle] = useState<string>('');
  const [value, setValue] = useState<JSONContent | null>(null);
  const { setSettings, settings } = useSettings();

  useEffect(() => {
    initPage();
  }, [page.id]);

  useEffect(() => {
    setSettings(project.settings as SettingsState);
  }, [project.id]);

  const initPage = async () => {
    try {
      const json = await fetch(`https://data.portal.dev/${page.sourceUrl}`).then((res) => res.json());
      setTitle('Untitled'); // Should come from project?
      setValue(json.value);
    } catch (e) {
      console.error('failed getting file');
      console.error(e);
    }
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        {!!settings.codeBlocks?.theme && (
          <link rel="stylesheet" href={`https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.5.1/styles/${settings.codeBlocks.theme}`} />
        )}
      </Helmet>
      <div className="flex justify-center">
        <div style={{ width: '930px' }}>{value && <EditorContent pageId={page.id} projectId={project.id} value={value} />}</div>
      </div>
    </>
  );
};
