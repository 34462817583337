import { NodeViewWrapper, NodeViewContent, NodeViewProps } from '@tiptap/react';

import { useSettings } from '../hooks';
import { Focus } from './focus-component';

export const Component: React.FC<NodeViewProps> = (props) => {
  const { settings } = useSettings();

  const style: React.CSSProperties = {
    backgroundColor: settings.codeBlocks?.backgroundColor ? settings?.colors?.[settings.codeBlocks.backgroundColor] : undefined,
    borderRadius: `${settings.codeBlocks?.borderRadius}px`,
    padding: `${settings.codeBlocks?.padding}rem`,
  };

  const wrapperStyle: React.CSSProperties = {
    marginTop: `${settings.codeBlocks?.marginTop}rem`,
    marginBottom: `${settings.codeBlocks?.marginBottom}rem`,
    fontSize: `0.8rem`,
  };

  return (
    <NodeViewWrapper style={wrapperStyle}>
      <Focus {...props}>
        <select
          className="absolute top-8 right-8 text-xs p-4 opacity-0 group-hover:opacity-100"
          contentEditable={false}
          defaultValue={props.node.attrs.language}
          onChange={(event) => props.updateAttributes({ language: event.target.value })}
        >
          <option value="null">auto</option>
          <option disabled>—</option>
          {props.extension.options.lowlight.listLanguages().map((lang: string, index: number) => (
            <option key={index} value={lang}>
              {lang}
            </option>
          ))}
        </select>
        <pre className="w-full">
          <NodeViewContent as="code" className="hljs font-mono" style={style} />
        </pre>
      </Focus>
    </NodeViewWrapper>
  );
};
