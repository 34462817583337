import { Loader } from 'lucide-react';
import classnames from 'classnames';

interface Props {
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
}

export const Button: React.FC<Props> = ({ children, onClick, loading, disabled }) => {
  return (
    <button
      type="button"
      disabled={disabled || loading}
      onClick={onClick}
      className={classnames(
        disabled || loading ? 'bg-gray-400 hover:bg-gray-400' : 'bg-gray-600 hover:bg-gray-700',
        'w-full inline-flex items-center justify-center rounded-md border border-transparent shadow-sm px-12 py-8 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:col-start-2 sm:text-sm'
      )}
    >
      {loading && <Loader width={16} height={16} className="animate-spin opacity-50 mr-8" />}
      {children}
    </button>
  );
};
