import { bgAndTextColors, infoColors, primaryColors, secondaryColors, tertiaryColors } from '../../constants';
import { SettingsState } from '../../hocs';
import { useSettings } from '../../hooks';

interface Props {
  value: keyof SettingsState['colors'];
  onSelect: (color: keyof SettingsState['colors']) => void;
}

export const ColorPalette: React.FC<Props> = ({ onSelect }) => {
  const { settings } = useSettings();
  const { colors } = settings;

  return (
    <div className="p-8 bg-white">
      <div className="flex">
        {bgAndTextColors.map((c) => (
          <div onClick={() => onSelect(c.value)} className="w-32 h-32 cursor-pointer" style={{ backgroundColor: colors[c.value] }} />
        ))}
      </div>
      <div className="flex">
        {primaryColors.map((c) => (
          <div onClick={() => onSelect(c.value)} className="w-32 h-32 cursor-pointer" style={{ backgroundColor: colors[c.value] }} />
        ))}
      </div>
      <div className="flex">
        {secondaryColors.map((c) => (
          <div onClick={() => onSelect(c.value)} className="w-32 h-32 cursor-pointer" style={{ backgroundColor: colors[c.value] }} />
        ))}
      </div>
      <div className="flex">
        {tertiaryColors.map((c) => (
          <div onClick={() => onSelect(c.value)} className="w-32 h-32 cursor-pointer" style={{ backgroundColor: colors[c.value] }} />
        ))}
      </div>
      <div className="flex">
        {infoColors.map((c) => (
          <div onClick={() => onSelect(c.value)} className="w-32 h-32 cursor-pointer" style={{ backgroundColor: colors[c.value] }} />
        ))}
      </div>
    </div>
  );
};
