import { Layout, RangeInput, Cell, Dropdown } from '..';
import { useSettings } from '../../hooks';
import { hljsThemes } from '../../constants';

export const SettingsCodeBlocks: React.FC = () => {
  const { settings, setCodeBlockProps } = useSettings();

  return (
    <Layout>
      <Cell>
        <Dropdown
          options={hljsThemes}
          selected={settings.codeBlocks?.theme}
          onSelect={(value) => {
            setCodeBlockProps({
              ...settings.codeBlocks,
              theme: value,
            });
          }}
        />
      </Cell>
      <Cell direction="row">
        <RangeInput
          min={0}
          max={5}
          step={0.1}
          label="Margin Top"
          value={settings.codeBlocks?.marginTop}
          onChange={(value) => {
            setCodeBlockProps({
              ...settings.codeBlocks,
              marginTop: value,
            });
          }}
        />
        <RangeInput
          min={0}
          max={5}
          step={0.1}
          label="Margin Bottom"
          value={settings.codeBlocks?.marginBottom}
          onChange={(value) => {
            setCodeBlockProps({
              ...settings.codeBlocks,
              marginBottom: value,
            });
          }}
        />
      </Cell>
      <Cell direction="row">
        <RangeInput
          min={0}
          max={2}
          step={0.1}
          label="Padding"
          value={settings.codeBlocks?.padding}
          onChange={(value) => {
            setCodeBlockProps({
              ...settings.codeBlocks,
              padding: value,
            });
          }}
        />
        <RangeInput
          min={0}
          max={16}
          step={1}
          label="Border Radius"
          value={settings.codeBlocks?.borderRadius}
          onChange={(value) => {
            setCodeBlockProps({
              ...settings.codeBlocks,
              borderRadius: value,
            });
          }}
        />
      </Cell>
    </Layout>
  );
};
