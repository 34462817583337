import { useMemo } from 'react';

import { NavigationItem } from '../types';
import { useProject } from './useProject';

export interface NavigationTreeItem extends NavigationItem {
  children: NavigationTreeItem[];
}

const buildTree = (navigationItems: NavigationItem[], parent: string | null): NavigationTreeItem[] => {
  const allTreeItems = navigationItems.map((item) => ({ ...item, children: [] } as NavigationTreeItem));
  const treeItemsById = new Map<string, NavigationTreeItem>();
  allTreeItems.forEach((item) => treeItemsById.set(item.id, item));
  allTreeItems.forEach((item) => {
    item.parentId && treeItemsById.get(item.parentId)!.children.push(item);
  });
  allTreeItems.forEach((item) => item.children.sort(({ sortingWeight: w1 }, { sortingWeight: w2 }) => w2 - w1));
  return allTreeItems.filter((item) => !item.parentId);
};

export const useNavigationTree = (): NavigationTreeItem[] => {
  const { data: project } = useProject();
  return useMemo(() => buildTree(project?.navigationItems || [], null), [project?.navigationItems]);
};
