import { ReactNodeViewRenderer } from '@tiptap/react';
import Paragraph from '@tiptap/extension-paragraph';

import { EditorComopnent } from './paragraph-component';

export const CustomParagraph = Paragraph.extend({
  draggable: true,
  addNodeView() {
    return ReactNodeViewRenderer(EditorComopnent);
  },
});
