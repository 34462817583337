import { Layout, RangeInput, Cell } from '..';
import { useSettings } from '../../hooks';

export const SettingsLists: React.FC = () => {
  const { settings, setListProps } = useSettings();

  return (
    <Layout>
      <Cell direction="row">
        <RangeInput
          min={0}
          max={5}
          step={0.1}
          label="Margin Top"
          value={settings.lists?.marginTop}
          onChange={(value) => {
            setListProps({
              ...settings.lists,
              marginTop: value,
            });
          }}
        />
        <RangeInput
          min={0}
          max={5}
          step={0.1}
          label="Margin Bottom"
          value={settings.lists?.marginBottom}
          onChange={(value) => {
            setListProps({
              ...settings.lists,
              marginBottom: value,
            });
          }}
        />
      </Cell>
      <Cell direction="row">
        <RangeInput
          min={0.5}
          max={5}
          step={0.1}
          label="Line Height"
          value={settings.lists?.lineHeight}
          onChange={(value) => {
            setListProps({
              ...settings.lists,
              lineHeight: value,
            });
          }}
        />
      </Cell>
    </Layout>
  );
};
