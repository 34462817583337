import { BrowserRouter, Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { EditorPage, LoginPage, RequireAuth, SignupPage, DashboardPage, ProjectPage, LogoutPage } from '../';
import { ModalProvider, SettingsContextProvider } from '../../hocs';

const Component: React.FC = () => {
  return <Outlet />;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <QueryClientProvider client={queryClient}>
              <SettingsContextProvider>
                <ModalProvider>
                  <Component />
                </ModalProvider>
              </SettingsContextProvider>
            </QueryClientProvider>
          }
        >
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <DashboardPage />
              </RequireAuth>
            }
          />
          <Route
            path="/editor/:projectSlug"
            element={
              <RequireAuth>
                <ProjectPage />
              </RequireAuth>
            }
          />
          <Route
            path="/editor/:projectSlug/*"
            element={
              <RequireAuth>
                <EditorPage />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
