import classnames from 'classnames';
import { useMemo, useState } from 'react';
import { Cell, Layout } from '..';
import { CalloutsProps } from '../../hocs';
import { CalloutNode } from '../../tiptap/callout/callout-types';
import { CalloutViewerComponent } from '../../tiptap/callout';

interface Tabs {
  value: keyof CalloutsProps;
  label: string;
}

const types: Tabs[] = [
  {
    value: 'success',
    label: 'Success',
  },
  {
    value: 'information',
    label: 'Information',
  },
  {
    value: 'attention',
    label: 'Attention',
  },
  {
    value: 'danger',
    label: 'Danger',
  },
];

export const SettingsCallouts: React.FC = () => {
  const [selected, setSelected] = useState(0);
  return (
    <div>
      <nav className="-mb-px flex space-x-8">
        {types.map((tab, i) => (
          <span
            key={tab.value}
            onClick={() => setSelected(i)}
            className={classnames(
              selected === i
                ? 'border-purple-500 text-purple-600'
                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
              'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
            )}
          >
            {tab.label}
          </span>
        ))}
      </nav>
      <CalloutSettingsContent calloutStyle={types[selected].value} />
    </div>
  );
};

const CalloutSettingsContent: React.FC<{ calloutStyle: keyof CalloutsProps }> = ({ calloutStyle }) => {
  const node = useMemo(
    () => ({
      type: 'callout',
      attrs: {
        calloutStyle,
      },
      content: [{ type: 'text', text: 'The quick brown fox jumps over the lazy dog', marks: [] }],
    }),
    [calloutStyle]
  ) as CalloutNode;
  return (
    <div className="pt-16">
      <Layout>
        <Cell>
          <CalloutViewerComponent node={node} />
        </Cell>
      </Layout>
    </div>
  );
};
