import { NodeViewWrapper, NodeViewContent, NodeViewProps } from '@tiptap/react';
import { mergeAttributes } from '@tiptap/core';

import { Placeholder } from './placeholder-component';
import { useSettings } from '../hooks';
import { Focus } from './focus-component';

export const Component: React.FC<NodeViewProps> = (props) => {
  const tag = props.node.type.name === 'bulletList' ? 'ul' : 'ol';
  const { settings } = useSettings();

  const attributes = mergeAttributes(props.extension.options.HTMLAttributes, props.node.attrs);
  const typography = settings?.typography.p;

  const placeholderStyle: React.CSSProperties = {
    fontSize: `${typography.size}rem`,
    fontFamily: typography.font,
    letterSpacing: `${typography.letterSpacing}px`,
  };

  const style = {
    // marginTop: `${settings.lists?.marginTop}rem`,
    // marginBottom: `${settings.lists?.marginBottom}rem`,
    // lineHeight: `${settings.lists?.lineHeight}em`,
  };

  return (
    <NodeViewWrapper>
      <Focus {...props}>
        <NodeViewContent as={tag} className={attributes.class} style={style} />
        <Placeholder style={{ ...placeholderStyle, ...style }} {...props} />
      </Focus>
    </NodeViewWrapper>
  );
};
