import { mergeAttributes, ReactNodeViewRenderer } from '@tiptap/react';
import Paragraph from '@tiptap/extension-paragraph';

import { Component } from './callout-component';
import { attribute } from '../attribute';
import { elementTypeAttribute, parseByElementType } from '../utils';

export const Callout = Paragraph.extend({
  name: 'callout',
  draggable: true,
  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },
  addAttributes() {
    return {
      calloutStyle: attribute('callout-style', 'information'),
    };
  },
  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, elementTypeAttribute('callout')), 0];
  },
  parseHTML() {
    return parseByElementType('callout');
  },
});
