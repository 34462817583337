import { Editor, Range } from '@tiptap/core';
import { forwardRef } from 'react';
import classnames from 'classnames';

import { Text } from '..';

interface CommandProps {
  editor: Editor;
  range: Range;
}

export interface CommandItem {
  id: string;
  title: string;
  subtitle: string;
  icon: React.ReactNode;
  command: (props: CommandProps) => void;
}

interface Props {
  item: CommandItem;
  hover: boolean;
  onClick: (e: React.MouseEvent) => void;
  onMouseOver: (e: React.MouseEvent) => void;
}

export const CommandListItem = forwardRef<HTMLDivElement, Props>(({ item, hover, onMouseOver, onClick }, ref) => {
  return (
    <div
      ref={ref}
      onClick={onClick}
      onMouseOver={onMouseOver}
      className={classnames(hover && 'bg-gray-100', 'p-8 flex gap-12 items-center cursor-pointer')}
    >
      <div className="pl-4">{item.icon}</div>
      <div className="flex flex-col">
        <Text weight="normal" size="small">
          {item.title}
        </Text>
        <Text size="tiny">{item.subtitle}</Text>
      </div>
    </div>
  );
});
