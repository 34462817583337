import { NodeViewWrapper, NodeViewContent } from '@tiptap/react';
import { mergeAttributes } from '@tiptap/core';

import { Focus } from './focus-component';
import { AttributeEditor, Slideover } from '../components';
import { useState } from 'react';

export const Component: React.FC<any> = (props) => {
  const { extension, node, updateAttributes } = props;
  const [showSettings, setShowSettings] = useState(false);

  const attributes = mergeAttributes(extension.options.HTMLAttributes, {
    class: `
      block
      col-span-${node.attrs.span}
      my-${node.attrs.my}
      bg-${node.attrs.bg}
      drop-shadow-${node.attrs['drop-shadow']}
    `,
  });

  const hideSettings = () => {
    setShowSettings(false);
  };

  const onClickSettings = () => {
    setShowSettings(true);
  };

  return (
    <NodeViewWrapper>
      <Focus onClickSettings={onClickSettings} {...props}>
        <NodeViewContent className={attributes.class} style={{ padding: `${node.attrs.py}px`, borderRadius: `${node.attrs.rounded}px` }} />
      </Focus>
      <Slideover show={showSettings} onClose={hideSettings}>
        <AttributeEditor
          updateAttributes={updateAttributes}
          attributes={[
            {
              key: 'py',
              label: 'Vertical Padding',
              value: node.attrs.py,
              type: 'slider',
              min: 0,
              max: 100,
            },
            {
              key: 'rounded',
              value: node.attrs.rounded,
              label: 'Border Radius',
              type: 'slider',
              min: 0,
              max: 100,
            },
            {
              key: 'bg',
              value: node.attrs.bg,
              label: 'Background Color',
              type: 'color',
            },
          ]}
        />
      </Slideover>
    </NodeViewWrapper>
  );
};
