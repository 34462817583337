import { useQuery } from 'react-query';
import { projectsService } from '../portal-api-client';

import { Project } from '../types';

export const useProjects = () => {
  const query = useQuery<Project[]>('projects', async () => {
    const res = await projectsService.find({ order: { createdAt: 1 } });
    return res.data;
  });

  return query;
};
