import { useNavigate } from 'react-router-dom';

import { Button, Layout, Cell, TopNav } from '../';
import { useMutations, useProjects } from '../../hooks';
import { Card } from '../card';
import { Text } from '../text';

export const DashboardPage: React.FC = () => {
  const { createProject, removeProject } = useMutations();
  const projectsQuery = useProjects();
  const navigate = useNavigate();

  if (projectsQuery.isLoading) {
    return <div>Loading projects</div>;
  }

  const onClickCreateProject = () => {
    createProject.mutate();
  };

  return (
    <div>
      <TopNav />
      <div className="max-w-7xl mx-auto pt-32">
        <div className="max-w-5xl mx-auto px-2 sm:px-6 lg:px-8">
          <Layout>
            {projectsQuery.data &&
              projectsQuery.data.map((project) => (
                <Cell key={project.id} span={4}>
                  <Card
                    actions={[
                      {
                        label: 'Open',
                        onClick: () => navigate(`/editor/${project.slug}`),
                      },
                      {
                        label: 'Remove',
                        onClick: () => removeProject.mutate(project.id),
                      },
                    ]}
                  >
                    <Text weight="normal">{project.title}</Text>
                    <br />
                    <Text size="tiny">
                      id:{project.id.substring(0, 5)} <br /> slug: {project.slug}
                    </Text>
                  </Card>
                </Cell>
              ))}
          </Layout>
          <Button onClick={onClickCreateProject} loading={createProject.isLoading}>
            Create Project
          </Button>
        </div>
      </div>
    </div>
  );
};
