import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';

import { AuthForm, AuthFormValues, validateAuthForm, Button, Card, Layout, Cell } from '..';
import { useMutations } from '../../hooks';

export const SignupPage: React.FC = () => {
  const { signup } = useMutations();
  const navigate = useNavigate();

  const handleSubmit = async (values: AuthFormValues) => {
    signup.mutate(values, {
      onSuccess: () => {
        navigate('/dashboard');
      },
    });
  };

  return (
    <Formik validate={validateAuthForm} initialValues={{ email: '', password: '' }} onSubmit={handleSubmit}>
      {({ submitForm }) => (
        <div className="min-h-full flex flex-col justify-center py-64 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-24 text-center text-3xl font-extrabold text-gray-900">Sign Up</h2>
          </div>

          <div className="mt-24 sm:mx-auto sm:w-full sm:max-w-md">
            <Card>
              <Layout>
                <Cell>
                  <AuthForm />
                </Cell>
                <Cell>
                  <Button loading={signup.isLoading} onClick={submitForm}>
                    Sign Uppp
                  </Button>
                </Cell>
              </Layout>
            </Card>
          </div>
        </div>
      )}
    </Formik>
  );
};
