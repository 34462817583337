interface Props {
  label?: string;
  value: number;
  min: number;
  max: number;
  step?: number;
  onChange: (value: number) => void;
  name?: string;
}

export const RangeInput: React.FC<Props> = (props) => {
  const onChange = (e: React.ChangeEvent<any>) => {
    props.onChange(parseFloat(e.target.value));
  };

  return (
    <div className="w-full">
      {props.label && <label className="block text-sm font-medium text-gray-700">{props.label}</label>}
      <input step={props.step} onChange={onChange} name={props.name} type="range" value={props.value} min={props.min} max={props.max} />
    </div>
  );
};
