export const attribute = (name: string, defaultValue: string) => {
  return {
    default: defaultValue,
    parseHTML: (element: HTMLElement) => element.getAttribute(`data-${name}`),
    renderHTML: (attributes: Record<string, any>) => {
      if (!attributes[name]) {
        return {};
      }

      return {
        [`data-${name}`]: attributes[name],
      };
    },
  };
};
