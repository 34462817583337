import { FieldProps } from 'formik';
import { get } from 'lodash';

import { Input } from '..';
import { FormField } from '../form-field';

interface Props extends FieldProps {
  disabled: boolean;
  type: string;
  dataHook?: string;
  inputref?: string;
  units?: string;
  placeholder?: string;
  label: string;
  autoFocus?: boolean;
}

const InputField: React.FC<Props> = (props) => {
  const showErrors = props.form.submitCount > 0;
  const error = get(props, `form.errors.${props.field.name}`) as string;
  const hasError = !!showErrors && !!error;

  return (
    <FormField name={props.field.name} label={props.label}>
      <Input
        type={props.type}
        autoFocus={props.autoFocus}
        onChange={props.field.onChange}
        placeholder={props.placeholder}
        value={props.field.value}
        name={props.field.name}
        error={hasError ? error : null}
      />
    </FormField>
  );
};

InputField.defaultProps = {
  disabled: false,
  type: 'text',
};

export { InputField };
