import { initialState } from './initial-state';
import {
  SET_CODE_BLOCK_PROPS,
  SET_IMAGE_PROPS,
  SET_LIST_PROPS,
  SET_SETTINGS,
  SET_COLOR,
  SET_TYPE,
  SettingsAction,
  SettingsState,
} from './types';

export function reducer(state = initialState, action: SettingsAction): SettingsState {
  switch (action.type) {
    case SET_SETTINGS: {
      return {
        ...action.payload,
        typography: action.payload.typography || initialState.typography,
        colors: action.payload.colors || initialState.colors,
        callouts: action.payload.callouts || initialState.callouts,
      };
    }

    case SET_IMAGE_PROPS: {
      return {
        ...state,
        images: action.payload,
      };
    }

    case SET_LIST_PROPS: {
      return {
        ...state,
        lists: action.payload,
      };
    }

    case SET_CODE_BLOCK_PROPS: {
      return {
        ...state,
        codeBlocks: action.payload,
      };
    }

    case SET_COLOR: {
      return {
        ...state,
        colors: {
          ...state.colors,
          [action.payload.key]: action.payload.value,
        },
      };
    }

    case SET_TYPE: {
      return {
        ...state,
        typography: {
          ...state.typography,
          [action.payload.key]: action.payload.value,
        },
      };
    }

    default:
      return {
        ...state,
      };
  }
}
