import { ReactNodeViewRenderer } from '@tiptap/react';
import BulletList from '@tiptap/extension-bullet-list';

import { Component } from './list-component';

export const CustomBulletList = BulletList.extend({
  draggable: true,
  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },
});
