import { NodeViewWrapper, NodeViewContent, NodeViewProps } from '@tiptap/react';
import { mergeAttributes } from '@tiptap/core';

import { Placeholder } from './placeholder-component';
import { useSettings } from '../hooks';
import { Focus } from './focus-component';
import { SettingsState, TypeProps } from '../hocs';

export const EditorComopnent: React.FC<NodeViewProps> = (props) => {
  const { settings } = useSettings();

  const attributes = mergeAttributes(props.extension.options.HTMLAttributes, props.node.attrs);
  const typography = settings?.typography.p;
  const style = getStyle(typography, settings);

  return (
    <NodeViewWrapper>
      <Focus {...props}>
        <NodeViewContent as="p" className={attributes.class} style={style} />
        <Placeholder style={style} {...props} />
      </Focus>
    </NodeViewWrapper>
  );
};

export const getStyle = (typography: TypeProps, settings: SettingsState): React.CSSProperties => {
  return {
    color: settings?.colors?.[typography.color],
    marginTop: `${typography.marginTop}rem`,
    marginBottom: `${typography.marginBottom}rem`,
    fontWeight: typography.weight,
    fontSize: `${typography.size}rem`,
    fontFamily: typography.font,
    letterSpacing: `${typography.letterSpacing}px`,
    lineHeight: `${typography.lineHeight}em`,
    textTransform: typography.appearance,
    backgroundColor: typography.backgroundColor ? settings?.colors?.[typography.backgroundColor] : undefined,
  };
};

export type TextNode = {
  type: 'text';
  marks: string[];
  text: string;
};

export type ParagraphNode = {
  type: 'paragraph';
  content: TextNode[];
};

export const ParagraphViewerComponent = ({ node, typography }: { node: ParagraphNode; typography?: TypeProps }) => {
  const { settings } = useSettings();
  typography = typography || settings?.typography.p;
  const style = getStyle(typography, settings);
  return (
    <p style={style}>
      {node.content.map((textNode) => (
        <TextViewerComponent node={textNode} />
      ))}
    </p>
  );
};

export const TextViewerComponent = ({ node }: { node: TextNode }) => {
  // this also needs to render marks
  return <>{node.text}</>;
};
