interface Attribute {
  key: string;
  label: string;
  value: any;
  type: 'slider' | 'color';
  min?: number;
  max?: number;
}

interface Props {
  attributes: Attribute[];
  updateAttributes: (attrs: Record<string, string>) => void;
}

export const AttributeEditor: React.FC<Props> = (props) => {
  const renderTool = (attr: Attribute) => {
    switch (attr.type) {
      case 'slider': {
        return (
          <input
            onChange={(e) =>
              props.updateAttributes({
                [attr.key]: e.target.value,
              })
            }
            type="range"
            value={attr.value}
            min={attr.min}
            max={attr.max}
          />
        );
      }
      case 'color': {
        return <div>color picker</div>;
      }
    }
  };

  return (
    <div>
      {props.attributes.map((attr) => (
        <div key={attr.key}>
          {attr.label}
          <br />
          {renderTool(attr)}
        </div>
      ))}
    </div>
  );
};
