import { User } from '../types';
import axios from './axios';

type LocalLoginParams = {
  email: string;
  password: string;
  strategy: 'local';
};

type JWTLoginParams = {
  accessToken: string;
  strategy: 'jwt';
};

type LoginParams = LocalLoginParams | JWTLoginParams;

type LoginResponse = {
  accessToken: string;
  user: User;
};

export class AuthService {
  async authenticate(params: LoginParams): Promise<LoginResponse> {
    const { data } = await axios.post<LoginResponse>('authentication', params);
    return data;
  }
}
