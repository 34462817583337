import { useSettings } from '../../hooks';
import { ParagraphNode, ParagraphViewerComponent } from '../paragraph-component';
import { ComponentSkeleton } from './callout-component';
import { CalloutNode } from './callout-types';

export const CalloutViewerComponent: React.FC<{ node: CalloutNode }> = ({ node }) => {
  const { settings } = useSettings();
  const typography = settings?.typography.p;
  const paragraphNode = { type: 'paragraph', content: node.content } as ParagraphNode;
  return (
    <div className="min-w-full">
      <ComponentSkeleton attrs={node.attrs}>
        <ParagraphViewerComponent node={paragraphNode} typography={typography} />
      </ComponentSkeleton>
    </div>
  );
};
