import { Node, mergeAttributes, textblockTypeInputRule } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { Component } from './heading-component';

export type Level = 1 | 2 | 3 | 4 | 5 | 6;
const levels: Level[] = [1, 2, 3, 4, 5, 6];

export interface HeadingOptions {
  HTMLAttributes: Record<string, any>;
  levelHTMLAttributes: Record<Level, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    heading: {
      setHeading: (attributes: { level: Level }) => ReturnType;
      toggleHeading: (attributes: { level: Level }) => ReturnType;
    };
  }
}

export const Heading = Node.create<HeadingOptions>({
  name: 'heading',

  addOptions() {
    return {
      HTMLAttributes: {},
      levelHTMLAttributes: {
        1: {},
        2: {},
        3: {},
        4: {},
        5: {},
        6: {},
      },
    };
  },

  content: 'inline*',

  group: 'block',

  defining: true,

  draggable: true,

  addAttributes() {
    return {
      level: {
        default: 1,
        rendered: false,
      },
    };
  },

  parseHTML() {
    return levels.map((level: Level) => ({
      tag: `h${level}`,
      attrs: { level },
    }));
  },

  renderHTML({ node, HTMLAttributes }) {
    const hasLevel = levels.includes(node.attrs.level);
    const level: Level = hasLevel ? node.attrs.level : levels[0];
    return [`h${level}`, mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, this.options.levelHTMLAttributes[level]), 0];
  },

  addCommands() {
    return {
      setHeading:
        (attributes) =>
        ({ commands }) => {
          if (!levels.includes(attributes.level)) {
            return false;
          }

          return commands.setNode(this.name, attributes);
        },
      toggleHeading:
        (attributes) =>
        ({ commands }) => {
          if (!levels.includes(attributes.level)) {
            return false;
          }

          return commands.toggleNode(this.name, 'paragraph', attributes);
        },
    };
  },

  addKeyboardShortcuts() {
    return levels.reduce(
      (items, level) => ({
        ...items,
        ...{
          [`Mod-Alt-${level}`]: () => this.editor.commands.toggleHeading({ level }),
        },
      }),
      {}
    );
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },

  addInputRules() {
    return levels.map((level) => {
      return textblockTypeInputRule({
        find: new RegExp(`^(#{1,${level}})\\s$`),
        type: this.type,
        getAttributes: {
          level,
        },
      });
    });
  },
});
