import { useContext, useEffect } from 'react';
import { debounce } from 'lodash';

import {
  SET_CODE_BLOCK_PROPS,
  SET_IMAGE_PROPS,
  SET_LIST_PROPS,
  SET_SETTINGS,
  SET_COLOR,
  SET_TYPE,
  SettingsContext,
  CodeBlockProps,
  SettingsState,
  ImagesProps,
  ListsProps,
  TypeProps,
} from '../hocs';
import { useProject } from './useProject';
import { projectsService } from '../portal-api-client';

const updateDebounce = debounce((f) => f(), 1500);

export const useSettings = () => {
  const { state, dispatch } = useContext(SettingsContext);
  const { data: project } = useProject();

  useEffect(() => {
    updateDebounce(() => saveSettings(state));
  }, [state]);

  const setColor = (key: string, value: string) => {
    dispatch({ type: SET_COLOR, payload: { key, value } });
  };

  const setType = (key: string, value: TypeProps) => {
    console.log({ key, value });
    dispatch({ type: SET_TYPE, payload: { key, value } });
  };

  const setSettings = (settings: SettingsState) => {
    return dispatch({ type: SET_SETTINGS, payload: settings });
  };

  const setImageProps = (value: ImagesProps) => {
    dispatch({ type: SET_IMAGE_PROPS, payload: value });
  };

  const setListProps = (value: ListsProps) => {
    dispatch({ type: SET_LIST_PROPS, payload: value });
  };

  const setCodeBlockProps = (value: CodeBlockProps) => {
    dispatch({ type: SET_CODE_BLOCK_PROPS, payload: value });
  };

  const saveSettings = (state: SettingsState) => {
    project && projectsService.patch(project.id, { settings: state });
  };

  return {
    setCodeBlockProps,
    settings: state,
    setImageProps,
    setListProps,
    saveSettings,
    setSettings,
    setColor,
    setType,
  };
};
