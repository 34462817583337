import { Editor, BubbleMenu, isTextSelection } from '@tiptap/react';
import { Bold, Italic, Underline, Code, Link } from 'lucide-react';

import { IconButton } from '..';

interface Props {
  editor: Editor | null;
}

export const EditorBubbleMenu: React.FC<Props> = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <BubbleMenu
      editor={editor}
      shouldShow={({ view, state, from, to }) => {
        const { doc, selection } = state;
        const { empty } = selection;
        const isEmptyTextBlock = !doc.textBetween(from, to).length && isTextSelection(state.selection);

        if (!view.hasFocus() || empty || isEmptyTextBlock) {
          return false;
        }

        if (editor.isActive('codeBlock') || editor.isActive('image') || editor.isActive('link')) {
          return false;
        }

        return true;
      }}
    >
      <div className="flex gap-2 bg-black rounded-lg p-4">
        <IconButton
          checked={editor.isActive('bold')}
          onClick={() => editor.commands.toggleBold()}
          icon={<Bold color="white" size={16} />}
        />
        <IconButton
          checked={editor.isActive('italic')}
          onClick={() => editor.commands.toggleItalic()}
          icon={<Italic color="white" size={16} />}
        />
        <IconButton
          checked={editor.isActive('underline')}
          onClick={() => editor.commands.toggleUnderline()}
          icon={<Underline color="white" size={16} />}
        />
        <IconButton
          checked={editor.isActive('code')}
          onClick={() => editor.commands.toggleCode()}
          icon={<Code color="white" size={16} />}
        />
        <IconButton
          checked={editor.isActive('link')}
          onClick={() => editor.commands.setLink({ href: '' })}
          icon={<Link color="white" size={16} />}
        />
      </div>
    </BubbleMenu>
  );
};
