import { useState } from 'react';
import classnames from 'classnames';

import { Layout, RangeInput, Cell, TypePreview, ColorPicker } from '..';
import { useSettings } from '../../hooks';
import { TypeKey } from '../../hocs';

interface TypeItem {
  value: TypeKey;
  label: string;
}

const types: TypeItem[] = [
  {
    value: 'p',
    label: 'Paragraph',
  },
  {
    value: 'h1',
    label: 'H1',
  },
  {
    value: 'h2',
    label: 'H2',
  },
  {
    value: 'h3',
    label: 'H3',
  },
  {
    value: 'h4',
    label: 'H4',
  },
  {
    value: 'h5',
    label: 'H5',
  },
  {
    value: 'h6',
    label: 'H6',
  },
];

export const SettingsTypography: React.FC = () => {
  const [selected, setSelected] = useState(0);
  return (
    <div>
      <nav className="-mb-px flex space-x-8">
        {types.map((tab, i) => (
          <span
            key={tab.value}
            onClick={() => setSelected(i)}
            className={classnames(
              selected === i
                ? 'border-purple-500 text-purple-600'
                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
              'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
            )}
          >
            {tab.label}
          </span>
        ))}
      </nav>
      <TypeSettings type={types[selected].value} />
    </div>
  );
};

interface TypeSettingsProps {
  type: TypeKey;
}

const TypeSettings: React.FC<TypeSettingsProps> = ({ type }) => {
  const { setType, settings } = useSettings();

  return (
    <div className="pt-16">
      <Layout>
        <Cell direction="row">
          <ColorPicker
            type="palette"
            value={settings.typography[type].color}
            onChange={(value) => {
              setType(type, {
                ...settings.typography[type],
                color: value,
              });
            }}
          />
        </Cell>
        <Cell direction="row">
          <RangeInput
            min={0.5}
            max={5}
            step={0.1}
            label="Font Size"
            value={settings.typography[type].size}
            onChange={(value) => {
              setType(type, {
                ...settings.typography[type],
                size: value,
              });
            }}
          />
          <RangeInput
            min={100}
            max={900}
            step={100}
            label="Font Weight"
            value={settings.typography[type].weight}
            onChange={(value) => {
              setType(type, {
                ...settings.typography[type],
                weight: value,
              });
            }}
          />
        </Cell>
        <Cell direction="row">
          <RangeInput
            min={0}
            max={5}
            step={0.1}
            label="Margin Top"
            value={settings.typography[type].marginTop}
            onChange={(value) => {
              setType(type, {
                ...settings.typography[type],
                marginTop: value,
              });
            }}
          />
          <RangeInput
            min={0}
            max={5}
            step={0.1}
            label="Margin Bottom"
            value={settings.typography[type].marginBottom}
            onChange={(value) => {
              setType(type, {
                ...settings.typography[type],
                marginBottom: value,
              });
            }}
          />
        </Cell>
        <Cell direction="row">
          <RangeInput
            min={-10}
            max={50}
            step={0.25}
            label="Letter Spacing"
            value={settings.typography[type].letterSpacing}
            onChange={(value) => {
              setType(type, {
                ...settings.typography[type],
                letterSpacing: value,
              });
            }}
          />
          <RangeInput
            min={0.5}
            max={5}
            step={0.1}
            label="Line Height"
            value={settings.typography[type].lineHeight}
            onChange={(value) => {
              setType(type, {
                ...settings.typography[type],
                lineHeight: value,
              });
            }}
          />
        </Cell>
        <Cell>
          <TypePreview type={type} />
        </Cell>
      </Layout>
    </div>
  );
};
