import { useQuery, UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';
import { projectsService } from '../portal-api-client';
import { FullProject } from '../types';

export const useProject = () => {
  const { projectSlug } = useParams<{ projectSlug: string }>();
  return useQuery(['project', projectSlug], async () => projectsService.getOne({ slug: projectSlug }, ['pages', 'navigationItems']), {
    enabled: !!projectSlug,
  }) as UseQueryResult<FullProject>;
};
