import { useState } from 'react';

import {
  Modal,
  SettingsColorPalette,
  SettingsNavigation,
  SettingsTypography,
  SettingsImages,
  SettingsLists,
  SettingsCodeBlocks,
  SettingsCallouts,
} from '..';
import { ModalType } from '../../hocs';

export interface SettingsModalType {
  type: ModalType.SETTINGS;
  props: any;
}

export const SettingsModal: React.FC = () => {
  const [selected, setSelected] = useState('colors');

  const renderContent = () => {
    switch (selected) {
      case 'colors': {
        return <SettingsColorPalette />;
      }
      case 'typography': {
        return <SettingsTypography />;
      }
      case 'images': {
        return <SettingsImages />;
      }
      case 'lists': {
        return <SettingsLists />;
      }
      case 'code-blocks': {
        return <SettingsCodeBlocks />;
      }
      case 'callouts': {
        return <SettingsCallouts />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Modal title="Site Settings" noPadding width="700px" height="512px">
      <div className="flex h-full">
        <div className="flex-0">
          <SettingsNavigation selected={selected} onClick={setSelected} />
        </div>
        <div className="flex-1 p-24">{renderContent()}</div>
      </div>
    </Modal>
  );
};
