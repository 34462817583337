import { NodeViewProps } from '@tiptap/react';

interface Props extends NodeViewProps {
  style?: React.CSSProperties;
}

export const Placeholder: React.FC<Props> = ({ style = {}, node, editor, getPos }) => {
  const { anchor } = editor.state.selection;
  const { nodeSize } = node;
  const pos = getPos();
  const isCurrent = anchor >= pos && anchor <= pos + nodeSize - 1;
  const isEmpty = node.content.childCount === 0;

  if (!isCurrent || !isEmpty) {
    return null;
  }

  return (
    <span
      className="text-gray-300 pointer-events-none"
      contentEditable={false}
      style={{
        fontSize: style.fontSize,
        fontFamily: style.fontFamily,
        letterSpacing: style.letterSpacing,
        lineHeight: style.lineHeight,
        whiteSpace: 'nowrap',
      }}
    >
      Type <span className="bg-gray-100 px-4 pb-2 rounded-sm text-gray-300">/</span> for commands.
    </span>
  );
};
