import { NodeViewWrapper, NodeViewContent, NodeViewProps } from '@tiptap/react';
import { Plus } from 'lucide-react';
import { Focus } from './focus-component';

export const Component: React.FC<NodeViewProps> = (props) => {
  const onAddRow = (e: React.MouseEvent) => {
    console.log('dsfsfds');
    e.preventDefault();
    e.stopPropagation();
    props.editor.chain().focus().addRowAfter().run();
  };

  return (
    <NodeViewWrapper>
      <Focus {...props}>
        <div className="relative w-full">
          <NodeViewContent as="table" className="table-auto border-t border-l table border-gray-200 w-full" />
          <div className="-bottom-24 h-24 w-full absolute z-10" onClick={onAddRow} contentEditable={false}>
            <AddButton />
          </div>
          <div className="-right-24 w-24 h-full top-0 absolute z-10" contentEditable={false}>
            <AddButton />
          </div>
        </div>
      </Focus>
    </NodeViewWrapper>
  );
};

const AddButton: React.FC = () => {
  return (
    <div className="m-2 w-full h-full text-gray-500 opacity-0 flex items-center justify-center absolute bg-gray-100 cursor-pointer hover:bg-gray-200 group-hover:opacity-100">
      <Plus size={16} />
    </div>
  );
};
