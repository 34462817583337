import { useSettings } from '../../hooks';
import { TypeKey } from '../../hocs';

interface Props {
  type: TypeKey;
}

export const TypePreview: React.FC<Props> = ({ type }) => {
  const { settings } = useSettings();

  const typography = settings?.typography[type];
  const style = {
    color: settings?.colors?.[typography.color],
    marginTop: `${typography.marginTop}rem`,
    marginBottom: `${typography.marginBottom}rem`,
    fontWeight: typography.weight,
    fontSize: `${typography.size}rem`,
    fontFamily: typography.font,
    letterSpacing: `${typography.letterSpacing}px`,
    lineHeight: `${typography.lineHeight}em`,
    textTransform: typography.appearance,
    backgroundColor: typography.backgroundColor ? settings?.colors?.[typography.backgroundColor] : undefined,
  };

  return (
    <div className="bg-gray-100 p-16 rounded-md">
      <div style={style}>The quick brown fox jumps over the lazy dog</div>
    </div>
  );
};
