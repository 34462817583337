import { Link, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';

import { AuthForm, AuthFormValues, validateAuthForm, Button, Card, Layout, Cell } from '..';
import { useMutations } from '../../hooks';

export const LoginPage: React.FC = () => {
  const { login } = useMutations();
  const navigate = useNavigate();

  const handleSubmit = async (values: AuthFormValues) => {
    login.mutate(values, {
      onSuccess: () => {
        navigate('/dashboard');
      },
    });
  };

  return (
    <Formik validate={validateAuthForm} initialValues={{ email: '', password: '' }} onSubmit={handleSubmit}>
      {({ submitForm }) => (
        <div className="min-h-full flex flex-col justify-center py-64 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-24 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
            <p className="mt-8 mb-16 text-center text-sm text-gray-600">
              Or{' '}
              <Link to="/signup" className="font-medium text-gray-600 hover:text-gray-500">
                Create a new account
              </Link>
            </p>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <Card>
              <Layout>
                <Cell>
                  <AuthForm />
                </Cell>
                <Cell>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-16 w-16 text-gray-600 focus:ring-gray-500 border-gray-300 rounded"
                      />
                      <label htmlFor="remember-me" className="ml-8 block text-sm text-gray-900">
                        Remember me
                      </label>
                    </div>

                    <div className="text-sm">
                      <a href="#" className="font-medium text-gray-600 hover:text-gray-500">
                        Forgot your password?
                      </a>
                    </div>
                  </div>
                </Cell>
                <Cell>
                  <Button loading={login.isLoading} onClick={submitForm}>
                    Sign in
                  </Button>
                </Cell>
              </Layout>
            </Card>
          </div>
        </div>
      )}
    </Formik>
  );
};
