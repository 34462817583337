import { Form, FastField, useFormikContext } from 'formik';
import { useEffect } from 'react';
import slugify from 'slugify';

import { DropdownField, DropdownOption, InputField, Layout, Cell } from '..';
import { NavigationItem } from '../../types';
import { useProject } from '../../hooks';

export interface PageFormErrors {
  title?: string;
  slug?: string;
}

export interface PageFormValues {
  title: string;
  slug: string;
  navigationItemParentId?: string;
}

export const PageForm: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<PageFormValues>();
  const { data: project } = useProject();

  useEffect(() => {
    const parent = project?.navigationItems.find((item) => item.id === values.navigationItemParentId);
    setFieldValue('slug', deriveSlug(values.title, parent));
  }, [values.title, values.navigationItemParentId]);

  const options = getOptions(project?.navigationItems);

  return (
    <Form className="w-full">
      <Layout>
        <Cell>
          <FastField
            label="Parent"
            component={DropdownField}
            name="navigationItemParentId"
            options={options}
            emptyOptionValue="top-level"
          />
        </Cell>
        <Cell>
          <FastField component={InputField} label="Page Title" placeholder="Eg., Home" name="title" autoFocus />
        </Cell>
        <Cell>
          <FastField component={InputField} label="Slug" placeholder="food/risotto" name="slug" autoFocus />
        </Cell>
      </Layout>
    </Form>
  );
};

export const validatePageForm = (values: PageFormValues) => {
  const errors: PageFormErrors = {};

  if (!values.title.trim().length) {
    errors.title = `Can't be empty`;
  }

  if (!values.slug.trim().length) {
    errors.slug = `Can't be empty`;
  }

  return errors;
};

const deriveSlug = (pageTitle: string, parent?: NavigationItem) => {
  const slug = slugify(pageTitle, { lower: true });
  return parent ? `${parent.href}/${slug}` : slug;
};

const getOptions = (navigationItems?: NavigationItem[]) => {
  const rootItem = { value: 'top-level', label: 'Top level' };
  let options: DropdownOption[] = navigationItems?.map((item) => ({ value: item.id, label: item.title })) || [];
  return (options = [rootItem, ...options]);
};
