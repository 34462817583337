import { CreateFileResponse } from '../types';
import axios from './axios';

export class FileUploadsService {
  async createSignedUrl({ projectId, fileName }: { projectId: string; fileName: string }): Promise<CreateFileResponse> {
    const { data } = await axios.post<CreateFileResponse>('file-uploads', {
      action: 'upload',
      projectId,
      fileName,
    });
    return data;
  }
}
