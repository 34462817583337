import { mergeAttributes, ReactNodeViewRenderer } from '@tiptap/react';
import { TextSelection } from 'prosemirror-state';
import { Node } from '@tiptap/core';

import { Component } from './columns-component';
import { attribute } from './attribute';
import { elementTypeAttribute, parseByElementType } from './utils';

export interface ColumnsOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    columns: {
      createCardList: (attributes: any) => ReturnType;
    };
  }
}

export const Columns = Node.create<ColumnsOptions>({
  name: 'columns',
  marks: '',
  content: 'card+',
  isolating: true,
  group: 'block',
  draggable: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      gap: attribute('gap', '12'),
    };
  },

  renderHTML({ HTMLAttributes, node }) {
    return [
      'div',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, { class: `flex gap-16 pt-16 pb-16` }, elementTypeAttribute('columns')),
      0,
    ];
  },

  addCommands() {
    return {
      createCardList:
        (options) =>
        ({ dispatch, editor, tr, commands }) => {
          const cards = [];

          for (let index = 0; index < 3; index += 1) {
            const card = editor.schema.nodes.card.create({ title: 'hello' });

            if (card) {
              cards.push(card);
            }
          }

          const cardList = editor.schema.nodes.columns.create(null, cards);

          if (dispatch) {
            const offset = tr.selection.anchor + 1;

            tr.replaceSelectionWith(cardList)
              .scrollIntoView()
              .setSelection(TextSelection.near(tr.doc.resolve(offset)));
          }

          return true;
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },

  parseHTML() {
    return parseByElementType('columns');
  },
});
