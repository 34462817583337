import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { useEffect, useRef, useState } from 'react';
import localForage from 'localforage';
import classnames from 'classnames';

import { useMutations, useSettings } from '../hooks';
import { Focus } from './focus-component';

export const Component: React.FC<NodeViewProps> = (props) => {
  const { settings } = useSettings();
  const [uploadAttempts, setUploadAttempts] = useState(1);
  const [preview, setPreview] = useState<string | undefined>(undefined);
  const { uploadFile } = useMutations();
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (props.node.attrs.loading) {
      uploadFileFromStorage();
    }
  }, [props.node.attrs.loading]);

  const uploadFileFromStorage = () => {
    localForage.getItem(`file:${props.node.attrs.id}`).then((file) => {
      if (!file) {
        if (uploadAttempts < 8) {
          setTimeout(uploadFileFromStorage, 200 * uploadAttempts);
          setUploadAttempts(uploadAttempts + 1);
        } else {
          props.updateAttributes({ ...props.node.attrs, loading: false, failed: true });
        }
        return;
      }

      const img = new Image();
      img.src = window.URL.createObjectURL(file as File);

      setPreview(img.src);

      img.onload = function () {
        uploadFile(file as File).then((res) => {
          if (res) {
            props.updateAttributes({ ...props.node.attrs, src: res.path, loading: false, id: null });
            localForage.removeItem(`file:${props.node.attrs.id}`);
          }
        });
      };
    });
  };

  return (
    <NodeViewWrapper>
      <Focus {...props}>
        <div
          style={{
            marginTop: `${settings.images?.marginTop || 0}rem`,
            marginBottom: `${settings.images?.marginBottom || 0}rem`,
            borderRadius: `${settings.images?.borderRadius || 0}px`,
          }}
          className={classnames(
            props.node.attrs.loading ? 'opacity-50' : 'opacity-100',
            props.selected ? 'ring-2 ring-primary' : '',
            'overflow-hidden'
          )}
          contentEditable={false}
        >
          <img ref={imgRef} alt="test" src={props.node.attrs.src || preview} />
        </div>
      </Focus>
    </NodeViewWrapper>
  );
};
