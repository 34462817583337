import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export const LogoutPage: React.FC = () => {
  const client = useQueryClient();
  const queryCache = client.getQueryCache();
  const mutationCache = client.getMutationCache();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('token');
    queryCache.clear();
    mutationCache.clear();
    navigate('/login');
  }, []);

  return <div>Ok bye</div>;
};
