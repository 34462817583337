import { Node, nodeInputRule, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { TextSelection } from 'prosemirror-state';
import { v4 as uuid } from 'uuid';
import localForage from 'localforage';

import { Component } from './image-component';

export interface ImageOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    image: {
      setImage: (options: { src: string; alt?: string }) => ReturnType;
      uploadImage: (options: { file: File }) => ReturnType;
    };
  }
}

const inputRegex = /(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))/;

export const Image = Node.create<ImageOptions>({
  name: 'image',
  marks: '',

  addOptions() {
    return {
      inline: false,
      HTMLAttributes: {},
    };
  },

  inline: false,
  group: 'block',
  draggable: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => {
          if (!attributes.dataId) {
            return {};
          }

          return {
            'data-id': attributes.dataId,
          };
        },
      },
      loading: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-loading'),
        renderHTML: (attributes) => {
          if (!attributes.loading) {
            return {};
          }

          return {
            'data-loading': attributes.loading,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'img[src]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addCommands() {
    return {
      setImage:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },

      uploadImage:
        (options) =>
        ({ chain }) => {
          const id = uuid();

          localForage.setItem(`file:${id}`, options.file);

          return chain()
            .insertContent({
              type: this.name,
              attrs: {
                id,
                loading: true,
              },
            })
            .command(({ tr }) => {
              const { parent, pos } = tr.selection.$from;
              const posAfter = pos + 1;
              const nodeAfter = tr.doc.nodeAt(posAfter);

              if (nodeAfter) {
                tr.setSelection(TextSelection.create(tr.doc, posAfter));
              } else {
                const node = parent.type.contentMatch.defaultType?.create();
                if (node) {
                  tr.insert(posAfter, node);
                  tr.setSelection(TextSelection.create(tr.doc, posAfter));
                }
              }

              tr.scrollIntoView();

              return true;
            })
            .run();
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: inputRegex,
        type: this.type,
        getAttributes: (match) => {
          const [alt, src, title] = match;
          return { src, alt, title };
        },
      }),
    ];
  },
});
