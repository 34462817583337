import { ReactNodeViewRenderer } from '@tiptap/react';
import Table from '@tiptap/extension-table';

import { Component } from './table-component';

export const CustomTable = Table.extend({
  draggable: true,
  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },
  addProseMirrorPlugins() {
    return [];
  },
});
