import { Extension, Editor, Range } from '@tiptap/core';
import Suggestion from '@tiptap/suggestion';

interface CommandProps {
  editor: Editor;
  range: Range;
  props: any;
}

export interface CommandsOptions {
  suggestion: {
    char?: string;
    command?: (props: CommandProps) => void;
  };
}

export const Commands = Extension.create<CommandsOptions>({
  name: 'commands',

  addOptions() {
    return {
      suggestion: {
        char: '/',
        command: ({ editor, range, props }) => {
          props.command({ editor, range });
        },
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});
