import { NodeViewWrapper, NodeViewContent, NodeViewProps } from '@tiptap/react';
import { mergeAttributes } from '@tiptap/core';
import { ElementType } from 'react';

import { Placeholder } from './placeholder-component';
import { useSettings } from '../hooks';
import { Focus } from './focus-component';
import { Level } from './heading-extension';

export const Component: React.FC<NodeViewProps> = (props) => {
  const { settings } = useSettings();

  const level: Level = props.node.attrs.level;
  const tag = `h${level}` as ElementType;
  const attributes = mergeAttributes(props.extension.options.HTMLAttributes);
  const typography = settings?.typography[`h${level}`];
  const style = {
    color: settings?.colors?.[typography.color],
    marginTop: `${typography.marginTop}rem`,
    marginBottom: `${typography.marginBottom}rem`,
    fontWeight: typography.weight,
    fontSize: `${typography.size}rem`,
    fontFamily: typography.font,
    letterSpacing: `${typography.letterSpacing}px`,
    lineHeight: `${typography.lineHeight}em`,
    textTransform: typography.appearance,
    backgroundColor: typography.backgroundColor ? settings?.colors?.[typography.backgroundColor] : undefined,
  };

  return (
    <NodeViewWrapper>
      <Focus {...props}>
        <NodeViewContent as={tag} className={attributes.class} style={style} />
        <Placeholder style={style} {...props} />
      </Focus>
    </NodeViewWrapper>
  );
};
